import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function TypeIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.47982 1.47994C1.93999 1.01977 2.58964 0.833496 3.33337 0.833496H4.66671C5.41045 0.833496 6.06009 1.01977 6.52026 1.47994C6.98043 1.94012 7.16671 2.58976 7.16671 3.3335V4.66683C7.16671 5.41057 6.98043 6.06021 6.52026 6.52038C6.06009 6.98056 5.41045 7.16683 4.66671 7.16683H3.33337C2.58964 7.16683 1.93999 6.98056 1.47982 6.52038C1.01965 6.06021 0.833374 5.41057 0.833374 4.66683V3.3335C0.833374 2.58976 1.01965 1.94012 1.47982 1.47994ZM2.18693 2.18705C1.98043 2.39354 1.83337 2.7439 1.83337 3.3335V4.66683C1.83337 5.25642 1.98043 5.60678 2.18693 5.81328C2.39342 6.01977 2.74378 6.16683 3.33337 6.16683H4.66671C5.2563 6.16683 5.60666 6.01977 5.81315 5.81328C6.01965 5.60678 6.16671 5.25642 6.16671 4.66683V3.3335C6.16671 2.7439 6.01965 2.39354 5.81315 2.18705C5.60666 1.98056 5.2563 1.8335 4.66671 1.8335H3.33337C2.74378 1.8335 2.39342 1.98056 2.18693 2.18705Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.47982 1.47994C9.93999 1.01977 10.5896 0.833496 11.3334 0.833496H12.6667C13.4104 0.833496 14.0601 1.01977 14.5203 1.47994C14.9804 1.94012 15.1667 2.58976 15.1667 3.3335V4.66683C15.1667 5.41057 14.9804 6.06021 14.5203 6.52038C14.0601 6.98056 13.4104 7.16683 12.6667 7.16683H11.3334C10.5896 7.16683 9.93999 6.98056 9.47982 6.52038C9.01965 6.06021 8.83337 5.41057 8.83337 4.66683V3.3335C8.83337 2.58976 9.01965 1.94012 9.47982 1.47994ZM10.1869 2.18705C9.98043 2.39354 9.83337 2.7439 9.83337 3.3335V4.66683C9.83337 5.25642 9.98043 5.60678 10.1869 5.81328C10.3934 6.01977 10.7438 6.16683 11.3334 6.16683H12.6667C13.2563 6.16683 13.6067 6.01977 13.8132 5.81328C14.0196 5.60678 14.1667 5.25642 14.1667 4.66683V3.3335C14.1667 2.7439 14.0196 2.39354 13.8132 2.18705C13.6067 1.98056 13.2563 1.8335 12.6667 1.8335H11.3334C10.7438 1.8335 10.3934 1.98056 10.1869 2.18705Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.47982 9.47994C9.93999 9.01977 10.5896 8.8335 11.3334 8.8335H12.6667C13.4104 8.8335 14.0601 9.01977 14.5203 9.47994C14.9804 9.94012 15.1667 10.5898 15.1667 11.3335V12.6668C15.1667 13.4106 14.9804 14.0602 14.5203 14.5204C14.0601 14.9806 13.4104 15.1668 12.6667 15.1668H11.3334C10.5896 15.1668 9.93999 14.9806 9.47982 14.5204C9.01965 14.0602 8.83337 13.4106 8.83337 12.6668V11.3335C8.83337 10.5898 9.01965 9.94012 9.47982 9.47994ZM10.1869 10.187C9.98043 10.3935 9.83337 10.7439 9.83337 11.3335V12.6668C9.83337 13.2564 9.98043 13.6068 10.1869 13.8133C10.3934 14.0198 10.7438 14.1668 11.3334 14.1668H12.6667C13.2563 14.1668 13.6067 14.0198 13.8132 13.8133C14.0196 13.6068 14.1667 13.2564 14.1667 12.6668V11.3335C14.1667 10.7439 14.0196 10.3935 13.8132 10.187C13.6067 9.98056 13.2563 9.8335 12.6667 9.8335H11.3334C10.7438 9.8335 10.3934 9.98056 10.1869 10.187Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.47982 9.47994C1.93999 9.01977 2.58964 8.8335 3.33337 8.8335H4.66671C5.41045 8.8335 6.06009 9.01977 6.52026 9.47994C6.98043 9.94012 7.16671 10.5898 7.16671 11.3335V12.6668C7.16671 13.4106 6.98043 14.0602 6.52026 14.5204C6.06009 14.9806 5.41045 15.1668 4.66671 15.1668H3.33337C2.58964 15.1668 1.93999 14.9806 1.47982 14.5204C1.01965 14.0602 0.833374 13.4106 0.833374 12.6668V11.3335C0.833374 10.5898 1.01965 9.94012 1.47982 9.47994ZM2.18693 10.187C1.98043 10.3935 1.83337 10.7439 1.83337 11.3335V12.6668C1.83337 13.2564 1.98043 13.6068 2.18693 13.8133C2.39342 14.0198 2.74378 14.1668 3.33337 14.1668H4.66671C5.2563 14.1668 5.60666 14.0198 5.81315 13.8133C6.01965 13.6068 6.16671 13.2564 6.16671 12.6668V11.3335C6.16671 10.7439 6.01965 10.3935 5.81315 10.187C5.60666 9.98056 5.2563 9.8335 4.66671 9.8335H3.33337C2.74378 9.8335 2.39342 9.98056 2.18693 10.187Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>

    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}