import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function DashboardLineIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
  
  const fillStyle = {fill: 'currentColor'}

  const Svg = () => (

    <svg width={props.width?props.width:"1em"} height={props.height?props.height:"1em"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path style={fillStyle} fill={props.style?.color?props.style.color:"#101020"} fillRule="evenodd" clipRule="evenodd" d="M15.4907 1.24017C13.823 0.752066 12.4381 0.680888 11.4719 1.37624C10.4917 2.0817 10.25 3.35009 10.25 4.67998V7.55998C10.25 8.86048 10.5129 9.94231 11.1928 10.697C11.8886 11.4694 12.8836 11.75 14 11.75H17.32C18.6499 11.75 19.9183 11.5083 20.6237 10.5281C21.3191 9.56191 21.2479 8.177 20.7598 6.5093C20.0379 4.05467 17.9453 1.96212 15.4907 1.24017ZM11.75 4.67998C11.75 3.40986 12.0083 2.83826 12.3481 2.59371C12.7019 2.33909 13.4569 2.20791 15.0689 2.67966C17.034 3.25784 18.7423 4.96628 19.3204 6.93136C19.7921 8.54317 19.6609 9.29809 19.4063 9.65187C19.1617 9.99165 18.5901 10.25 17.32 10.25H14C13.1164 10.25 12.6114 10.0306 12.3072 9.69298C11.9871 9.33764 11.75 8.69948 11.75 7.55998V4.67998Z"/>
      <path style={fillStyle} fill={props.style?.color?props.style.color:"#101020"} d="M8.40954 3.74485C8.8154 3.6621 9.07733 3.26599 8.99457 2.86013C8.91182 2.45427 8.51571 2.19234 8.10985 2.2751C3.11889 3.29277 -0.496793 8.09919 0.379234 13.5392L0.379533 13.5411C1.05143 17.6483 4.35026 20.9484 8.45949 21.6103C13.9222 22.4973 18.7285 18.8585 19.735 13.8477C19.8166 13.4416 19.5535 13.0462 19.1474 12.9647C18.7413 12.8831 18.346 13.1462 18.2644 13.5523C17.4109 17.8015 13.3372 20.8827 8.6999 20.1297L8.69887 20.1295C5.22887 19.571 2.42836 16.7717 1.86 13.2997C1.11662 8.68017 4.1808 4.60711 8.40954 3.74485Z"/>
    </svg>

  );

  return (
    <Icon component={Svg} {...props} />
  );
}

