import HttpUtils from '../utils/HttpUtils'

const AccountApi ={
    async list(data:{offset:number,limit?:number}) {
        const OFFSET_DEFAULT:number = 1;
        const LIMIT_DEFAULT:number = 25;
        const params:{offset:number,limit:number} = {
            offset:data.offset?data.offset:OFFSET_DEFAULT,
            limit:data.limit?data.limit:LIMIT_DEFAULT,
        }
        return await HttpUtils.sendJsonRPC('/v1','net_getAccountList',params);
    },

    async getAccountByAddress(address:string) {
        const params:{address:string} = {
            address:address
        }
        return await HttpUtils.sendJsonRPC('/v1','net_getAccountByAddress',params);
    }
}

export default AccountApi;
