import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const fillStyle = {fill: 'currentColor'}

const svg = () => (
    <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={fillStyle} fillRule="evenodd" clipRule="evenodd" d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C14.6986 22.75 17.165 21.7556 19.0527 20.1133L21.4697 22.5303C21.7626 22.8232 22.2374 22.8232 22.5303 22.5303C22.8232 22.2374 22.8232 21.7626 22.5303 21.4697L20.1133 19.0527C21.7556 17.165 22.75 14.6986 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z" fill={prop.color?prop.color:"#101020"}/>
    </svg>
);

let prop:any = undefined

export function SearchIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={svg} {...props} />
    );
}