import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function AgeIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33337 0.833496C3.05723 0.833496 2.83337 1.05735 2.83337 1.3335C2.83337 1.60964 3.05723 1.8335 3.33337 1.8335H8.66671C8.94285 1.8335 9.16671 1.60964 9.16671 1.3335C9.16671 1.05735 8.94285 0.833496 8.66671 0.833496H3.33337Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path d="M6.16671 6.3335C6.16671 6.05735 5.94285 5.8335 5.66671 5.8335C5.39056 5.8335 5.16671 6.05735 5.16671 6.3335V8.3335C5.16671 8.60964 5.39056 8.8335 5.66671 8.8335H7.66671C7.94285 8.8335 8.16671 8.60964 8.16671 8.3335C8.16671 8.05735 7.94285 7.8335 7.66671 7.8335H6.16671V6.3335Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.66671 2.8335C2.81243 2.8335 2.07972 3.04837 1.56399 3.56411C1.04825 4.07984 0.833374 4.81255 0.833374 5.66683V10.3335C0.833374 11.1878 1.04825 11.9205 1.56399 12.4362C2.07972 12.952 2.81243 13.1668 3.66671 13.1668H8.33337C9.18766 13.1668 9.92036 12.952 10.4361 12.4362C10.9518 11.9205 11.1667 11.1878 11.1667 10.3335V5.66683C11.1667 4.81255 10.9518 4.07984 10.4361 3.56411C9.92036 3.04837 9.18766 2.8335 8.33337 2.8335H3.66671ZM1.83337 5.66683C1.83337 4.96778 2.0085 4.53381 2.27109 4.27122C2.53369 4.00862 2.96765 3.8335 3.66671 3.8335H8.33337C9.03243 3.8335 9.46639 4.00862 9.72899 4.27122C9.99159 4.53381 10.1667 4.96778 10.1667 5.66683V10.3335C10.1667 11.0325 9.99159 11.4665 9.72899 11.7291C9.46639 11.9917 9.03243 12.1668 8.33337 12.1668H3.66671C2.96765 12.1668 2.53369 11.9917 2.27109 11.7291C2.0085 11.4665 1.83337 11.0325 1.83337 10.3335V5.66683Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path d="M2.83337 14.6668C2.83337 14.3907 3.05723 14.1668 3.33337 14.1668H8.66671C8.94285 14.1668 9.16671 14.3907 9.16671 14.6668C9.16671 14.943 8.94285 15.1668 8.66671 15.1668H3.33337C3.05723 15.1668 2.83337 14.943 2.83337 14.6668Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>

    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}