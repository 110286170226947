import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function ExpiredTimeIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.10661 1.28707C1.55611 0.990706 2.1401 0.833252 2.84006 0.833252H7.16006C7.86002 0.833252 8.44402 0.990706 8.89351 1.28707C9.34739 1.58632 9.63655 2.00987 9.76378 2.48355C10.0142 3.41583 9.62952 4.47032 8.84261 5.18363C8.84258 5.18366 8.84264 5.18361 8.84261 5.18363L5.74352 7.99992L8.84252 10.8161C8.84244 10.816 8.8426 10.8162 8.84252 10.8161C9.62933 11.5294 10.0142 12.5841 9.76378 13.5163C9.63655 13.99 9.34739 14.4135 8.89351 14.7128C8.44401 15.0091 7.86002 15.1666 7.16006 15.1666H2.84006C2.1401 15.1666 1.55611 15.0091 1.10661 14.7128C0.652728 14.4135 0.363576 13.99 0.236343 13.5163C-0.0140319 12.5842 0.370478 11.5299 1.15713 10.8166L4.2566 7.99992L1.1576 5.18372C1.15752 5.18364 1.15769 5.18379 1.1576 5.18372C0.370805 4.47041 -0.0140553 3.41577 0.236343 2.48355C0.363576 2.00987 0.652728 1.58632 1.10661 1.28707ZM5.00006 7.3243L8.17046 4.44322C8.73683 3.92986 8.93258 3.24397 8.79801 2.74296C8.73274 2.49997 8.58815 2.28352 8.34307 2.12194C8.09361 1.95746 7.71343 1.83325 7.16006 1.83325H2.84006C2.28669 1.83325 1.90651 1.95746 1.65705 2.12194C1.41198 2.28352 1.26738 2.49997 1.20211 2.74296C1.06754 3.24397 1.26282 3.92943 1.82918 4.44279L5.00006 7.3243ZM5.00006 8.67553L1.82966 11.5566C1.2633 12.07 1.06754 12.7559 1.20211 13.2569C1.26738 13.4999 1.41198 13.7163 1.65705 13.8779C1.90651 14.0424 2.28669 14.1666 2.84006 14.1666H7.16006C7.71343 14.1666 8.09361 14.0424 8.34307 13.8779C8.58815 13.7163 8.73274 13.4999 8.79801 13.2569C8.93258 12.7559 8.7373 12.0704 8.17094 11.5571L5.00006 8.67553Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}