import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const svg = () => (

    <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 14.2499C7.58579 14.2499 7.25 14.5857 7.25 14.9999C7.25 15.4142 7.58579 15.7499 8 15.7499H14C14.4142 15.7499 14.75 15.4142 14.75 14.9999C14.75 14.5857 14.4142 14.2499 14 14.2499H8Z" fill="#101020"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.4501 1.10549C12.5961 0.507656 11.5191 0.24459 10.4837 0.268869C9.44825 0.293148 8.38375 0.606854 7.55901 1.24831L2.16901 5.44831C1.60552 5.88676 1.134 6.53609 0.804179 7.2083C0.474406 7.88042 0.25 8.64944 0.25 9.35991V16.7699C0.25 19.5018 2.47347 21.7399 5.21 21.7399H16.79C19.5254 21.7399 21.75 19.503 21.75 16.7799V9.49991C21.75 8.74271 21.5017 7.93324 21.1361 7.23285C20.7707 6.53277 20.2484 5.86666 19.6288 5.43461L13.4501 1.10549ZM3.09099 6.63151L8.48046 2.43193C9.00571 2.02351 9.74188 1.78667 10.5188 1.76846C11.2958 1.75024 12.044 1.95229 12.5899 2.33434L18.7696 6.66415L18.7712 6.66521C19.1413 6.92318 19.5195 7.37731 19.8064 7.92697C20.0933 8.47659 20.25 9.04712 20.25 9.49991V16.7799C20.25 18.6769 18.6946 20.2399 16.79 20.2399H5.21C3.30653 20.2399 1.75 18.678 1.75 16.7699V9.35991C1.75 8.94038 1.89059 8.3994 2.15082 7.86902C2.4109 7.33895 2.75471 6.89306 3.09099 6.63151Z" fill="#101020"/>
    </svg>

);

let prop:any = undefined

export function ArkreenHomeIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={svg} {...props} />
    );
}