import { Select } from 'antd';
import { useState,useEffect  } from "react";
import TransactionApi from '../api/TransactionApi'
import type { ColumnsType } from 'antd/es/table';
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/ArkreenTable.css'
import '../common/css/ArkreenMain.css'
import {formatLongString,formatVisualizationDate,formatTxTypeTag} from '../utils/DataFormatUtils'
import { useNavigate,useLocation } from 'react-router-dom'
import ArkreenTable from '../components/ArkreenTable'
import '../common/css/ArkreenSelect.css'

interface DataType {
  hash: string;
  type: number;
  height: number;
  status:number;
  createTime: string;
}


function Transactions(){

  const navigate  = useNavigate()
  const location  = useLocation()

  function showTxDetail(hash:string){
    navigate('/tx/'+hash)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Hash',
      dataIndex: 'hash',
      key: 'hash',
      width:'180px',
      fixed: 'left',
    },
    {
      title: 'Block',
      dataIndex: 'height',
      key: 'height',
      width:'120px'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width:'180px'
    },
    {
      title: 'Age',
      dataIndex: 'createTime',
      key: 'createTime',
      width:'180px'
    },
  ];
  
  const [transactionList,setTransactionList] = useState([])

  async function getTransactions() {
    const data = {
      offset:currentPage,
      type:txType,
    }
    setLoading(true)
    const rsp:any = await TransactionApi.getTransactionList(data);
    if(!rsp.error){
      rsp.result.forEach((item: any,index: number) => {
        const hash = item.hash
        rsp.result[index].key = hash
        rsp.result[index].hash = <span onClick={() => {showTxDetail(hash)}} className="content-td-link">{formatLongString(hash,7,7)}</span>
        rsp.result[index].height = <span>{Number(item.height).toLocaleString()}</span>
        rsp.result[index].type = <span>{formatTxTypeTag(item.type)}</span>
        rsp.result[index].createTime = <span>{formatVisualizationDate(item.createTime)}</span>
      })
      setTransactionList(rsp.result)
    }else{
      if(transactionList.length>0){
        setTransactionList([])
      }
    }
    setLoading(false)
 }
 const utm = new URLSearchParams(location.search)
 const [txType, setTxType] = useState(utm.get("type") ? Number(utm.get("type")) : undefined)

 function handleTxTypeChange(value: string) {
  let data = new URLSearchParams(location.search);
  if(!value){
    data.delete('type')
  }else{
    data.set('type',value)
  }
  data.set('page',"1")
  navigate("?"+data.toString());
  setTxType(Number(value));
  setCurrentPage(1)
};

const [currentPage, setCurrentPage] = useState(utm.get("page") ? Number(utm.get("page")) : 1)
const [loading, setLoading] = useState(true)

 useEffect(() => {getTransactions();},[currentPage,txType])//eslint-disable-line

  return (
    <div className="arkreen-main">
      <div className="arkreen-main-title">
        <span>Transactions</span>
      </div>
      <div className="arkreen-main-content">
        <div className='arkreen-select-group'>
          <div className='arkreen-select-item arkreen-select-padding-right'>
          <span>
          <span className='arkreen-select-title'> Tx Type </span>
          <Select
            allowClear = {true}
            defaultValue={txType?""+txType:null}
            className ='arkreen-select'
            placeholder = 'Tx Type'
            onChange={handleTxTypeChange}
            options={[
              {value:"1",label:'TX_Register_Miner'},
              {value:'2',label:'TX_Onboard_Miner'},
              {value:'3',label:'TX_Transfer_Miner'},
              {value:'4',label:'TX_Terminate_Miner'},
              {value:'7',label:'TX_Commit_PoGG'},
              {value:'8',label:'TX_Report_PoGG'},
              {value:'9',label:'TX_Reward_PoGG'},
              {value:'10',label:'TX_Transfer'},
              {value:'11',label:'TX_Withdraw'},
              {value:'13',label:'TX_Airdrop_AKRE'}
            ]}/>
          </span>
          </div>
        </div>
        <ArkreenTable scroll={{ x: 660 }} currentPage={currentPage} columns={columns} datalist={transactionList} loading={loading} onPageChange={setCurrentPage}/>
      </div>
    </div>
  );
}

export default Transactions;