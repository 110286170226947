import { useState,useEffect  } from "react";
import BlockApi from '../api/BlockApi'
import type { ColumnsType } from 'antd/es/table';
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/ArkreenTable.css'
import '../common/css/ArkreenMain.css'
import {formatLongString,formatVisualizationDate,formatStatusIcon} from '../utils/DataFormatUtils'
import ArkreenTable from '../components/ArkreenTable'
import { useNavigate,useLocation } from 'react-router-dom'

interface DataType {
  blockHash: string;
  height: number;
  parentHash: number;
  timestamp: number;
  time: string;
  transactions:string;
}


function Blocks(){
  const location  = useLocation()
  const navigate  = useNavigate()

  function showBlockDetail(height:number){
    navigate('/block/'+height)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Height',
      dataIndex: 'height',
      key: 'height',
      width:'120px',
      fixed: 'left',
    },
    {
      title: 'Block Hash',
      dataIndex: 'hash',
      key: 'hash',
      width:'180px'
    },
    {
      title: 'Transactions',
      dataIndex: 'txAmount',
      key: 'txAmount',
      width:'120px',
    },
    {
      title: 'Age',
      dataIndex: 'createTime',
      key: 'createTime',
      width:'180px',
    },
    ];
  
  const [loading, setLoading] = useState(true)
  const utm = new URLSearchParams(location.search)
  const [currentPage, setCurrentPage] = useState(utm.get("page") ? Number(utm.get("page")) : 1)

  const [blockList,setBlockList] = useState([])

  async function getBlocks() {
    const data = {
      offset:currentPage,
    }
    setLoading(true)
    const rsp:any = await BlockApi.getBlockList(data);
    if(!rsp.error){
      rsp.result?.forEach((item: any,index: number) => {
        const height = item.height
        rsp.result[index].key = item.hash
        rsp.result[index].height = <span onClick={() => {showBlockDetail(height)}} className="content-td-link">{Number(height).toLocaleString()}</span>
        rsp.result[index].hash = item.hash?<span className="web3string">{formatLongString(item.hash,7,7)}</span>:formatStatusIcon('pending',"Pending")
        rsp.result[index].txAmount = <span>{Number(item.txAmount).toLocaleString()}</span>
        rsp.result[index].createTime = <span>{formatVisualizationDate(item.createTime)}</span>
      })
      setBlockList(rsp.result)
    }else{
      if(blockList.length>0){
        setBlockList([])
      }
    }
    setLoading(false)
 }

 useEffect(() => {getBlocks()},[currentPage])//eslint-disable-line

  return (
    <div className="arkreen-main">
      <div className="arkreen-main-title">
        <span>Blocks</span>
      </div>
      <div className="arkreen-main-content">
        <ArkreenTable scroll={{ x: 660 }} currentPage={currentPage} columns={columns} datalist={blockList} loading={loading} onPageChange={setCurrentPage}/>
      </div>
    </div>
  );
}

export default Blocks;