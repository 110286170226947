import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function WalletIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        
        <svg width={props.style?.width?props.style.width:"20px"} height={props.style?.height?props.style.height:"20px"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.4502 11.64V12.64C19.4502 12.91 19.2402 13.13 18.9602 13.14H17.5002C16.9702 13.14 16.4902 12.75 16.4502 12.23C16.4202 11.92 16.5402 11.63 16.7402 11.43C16.9202 11.24 17.1702 11.14 17.4402 11.14H18.9502C19.2402 11.15 19.4502 11.37 19.4502 11.64Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M15.9902 10.69C15.4902 11.18 15.2502 11.91 15.4502 12.67C15.7102 13.6 16.6202 14.19 17.5802 14.19H18.4502C19.0002 14.19 19.4502 14.64 19.4502 15.19V15.38C19.4502 17.45 17.7602 19.14 15.6902 19.14H4.2102C2.1402 19.14 0.450195 17.45 0.450195 15.38V8.65C0.450195 7.42 1.0402 6.33 1.9502 5.65C2.5802 5.17 3.3602 4.89 4.2102 4.89H15.6902C17.7602 4.89 19.4502 6.58 19.4502 8.65V9.09C19.4502 9.64 19.0002 10.09 18.4502 10.09H17.4302C16.8702 10.09 16.3602 10.31 15.9902 10.69Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M14.2002 2.82C14.4702 3.09 14.2402 3.51 13.8602 3.51L6.1802 3.5C5.7402 3.5 5.5102 2.96 5.8302 2.65L7.4502 1.02C8.8202 -0.34 11.0402 -0.34 12.4102 1.02L14.1602 2.79C14.1702 2.8 14.1902 2.81 14.2002 2.82Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}