import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function RewardIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"} d="M1.83337 8.00004C1.83337 4.59618 4.59618 1.83337 8.00004 1.83337C8.27618 1.83337 8.50004 1.60952 8.50004 1.33337C8.50004 1.05723 8.27618 0.833374 8.00004 0.833374C4.0439 0.833374 0.833374 4.0439 0.833374 8.00004C0.833374 11.9562 4.0439 15.1667 8.00004 15.1667C11.9562 15.1667 15.1667 11.9562 15.1667 8.00004C15.1667 7.7239 14.9429 7.50004 14.6667 7.50004C14.3906 7.50004 14.1667 7.7239 14.1667 8.00004C14.1667 11.4039 11.4039 14.1667 8.00004 14.1667C4.59618 14.1667 1.83337 11.4039 1.83337 8.00004Z"/>
            <path style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"} fillRule="evenodd" clipRule="evenodd" d="M8.00004 4.50004C8.27618 4.50004 8.50004 4.7239 8.50004 5.00004V5.16003H8.56004C9.47022 5.16003 10.1734 5.92507 10.1734 6.8267C10.1734 7.10284 9.94952 7.3267 9.67338 7.3267C9.39724 7.3267 9.17338 7.10284 9.17338 6.8267C9.17338 6.435 8.87652 6.16003 8.56004 6.16003H8.50004V7.64041L9.17438 7.87564C9.40932 7.95926 9.67777 8.09051 9.87829 8.3473C10.0833 8.60977 10.1667 8.93714 10.1667 9.31336C10.1667 10.1318 9.53325 10.8334 8.70003 10.8334H8.50004V11C8.50004 11.2762 8.27618 11.5 8.00004 11.5C7.7239 11.5 7.50004 11.2762 7.50004 11V10.8334H7.44672C6.53653 10.8334 5.83337 10.0683 5.83337 9.1667C5.83337 8.89056 6.05723 8.6667 6.33337 8.6667C6.60952 8.6667 6.83337 8.89056 6.83337 9.1667C6.83337 9.5584 7.13023 9.83336 7.44672 9.83336H7.50004V8.35067L6.83238 8.11776C6.6006 8.03526 6.33088 7.90636 6.12931 7.64968C5.92294 7.38689 5.84005 7.05832 5.84005 6.68003C5.84005 5.87044 6.4714 5.16003 7.3067 5.16003H7.50004V5.00004C7.50004 4.7239 7.7239 4.50004 8.00004 4.50004ZM7.3067 6.16003H7.50004V7.29157L7.16632 7.17515C7.0058 7.11787 6.94578 7.07026 6.91578 7.03205C6.89049 6.99985 6.84005 6.91508 6.84005 6.68003C6.84005 6.36963 7.07533 6.16003 7.3067 6.16003ZM8.50004 9.83336V8.6995L8.84047 8.81826C8.99782 8.87442 9.05908 8.92301 9.09013 8.96277C9.11683 8.99696 9.16671 9.08292 9.16671 9.31336C9.16671 9.62824 8.93348 9.83336 8.70003 9.83336H8.50004Z"/>
            <path style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"} d="M11.3334 1.50004C11.6095 1.50004 11.8334 1.7239 11.8334 2.00004V3.4596L14.3132 0.979821C14.5084 0.784559 14.825 0.784559 15.0203 0.979821C15.2155 1.17508 15.2155 1.49167 15.0203 1.68693L12.5405 4.16671H14C14.2762 4.16671 14.5 4.39056 14.5 4.66671C14.5 4.94285 14.2762 5.16671 14 5.16671H11.3334C11.1958 5.16671 11.0713 5.11118 10.9809 5.02132L10.9798 5.02026L10.9788 5.0192C10.9313 4.97151 10.8955 4.91664 10.8713 4.8581C10.8469 4.79915 10.8334 4.7345 10.8334 4.66671V2.00004C10.8334 1.7239 11.0572 1.50004 11.3334 1.50004Z"/>
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}