import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function TimestampIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"} d="M8.2533 5.00671C8.2533 4.73057 8.02944 4.50671 7.7533 4.50671C7.47715 4.50671 7.2533 4.73057 7.2533 5.00671V7.74005C7.2533 8.05768 7.36087 8.37598 7.51063 8.63857C7.66023 8.90091 7.87892 9.15499 8.15103 9.31645L8.15173 9.31686L10.2171 10.5494C10.4542 10.6909 10.7611 10.6134 10.9027 10.3763C11.0442 10.1391 10.9667 9.8322 10.7295 9.69069L8.66286 8.45736L8.66153 8.45657C8.57396 8.40468 8.46623 8.29562 8.3793 8.14319C8.29239 7.99078 8.2533 7.84241 8.2533 7.74005V5.00671Z"/>
            <path style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"} fillRule="evenodd" clipRule="evenodd" d="M8.00004 0.833374C4.0439 0.833374 0.833374 4.0439 0.833374 8.00004C0.833374 11.9562 4.0439 15.1667 8.00004 15.1667C11.9562 15.1667 15.1667 11.9562 15.1667 8.00004C15.1667 4.0439 11.9562 0.833374 8.00004 0.833374ZM1.83337 8.00004C1.83337 4.59618 4.59618 1.83337 8.00004 1.83337C11.4039 1.83337 14.1667 4.59618 14.1667 8.00004C14.1667 11.4039 11.4039 14.1667 8.00004 14.1667C4.59618 14.1667 1.83337 11.4039 1.83337 8.00004Z"/>
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}