import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';



export function ArkreenDocsIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    const svg = () => (
        <svg width={props.width?props.width:"1em"} height={props.height?props.height:"1em"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 8C6.5 7.58579 6.83579 7.25 7.25 7.25H14.75C15.1642 7.25 15.5 7.58579 15.5 8C15.5 8.41421 15.1642 8.75 14.75 8.75H7.25C6.83579 8.75 6.5 8.41421 6.5 8Z" fill="#101020"/>
            <path d="M7.25 13.25C6.83579 13.25 6.5 13.5858 6.5 14C6.5 14.4142 6.83579 14.75 7.25 14.75H14.75C15.1642 14.75 15.5 14.4142 15.5 14C15.5 13.5858 15.1642 13.25 14.75 13.25H7.25Z" fill="#101020"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.09467 2.09467C3.42914 0.760201 5.42603 0.25 8 0.25H14C16.574 0.25 18.5709 0.760201 19.9053 2.09467C21.2398 3.42914 21.75 5.42603 21.75 8V14C21.75 16.574 21.2398 18.5709 19.9053 19.9053C18.5709 21.2398 16.574 21.75 14 21.75H8C5.42603 21.75 3.42914 21.2398 2.09467 19.9053C0.760201 18.5709 0.25 16.574 0.25 14V8C0.25 5.42603 0.760201 3.42914 2.09467 2.09467ZM3.15533 3.15533C2.2398 4.07086 1.75 5.57397 1.75 8V14C1.75 16.426 2.2398 17.9291 3.15533 18.8447C4.07086 19.7602 5.57397 20.25 8 20.25H14C16.426 20.25 17.9291 19.7602 18.8447 18.8447C19.7602 17.9291 20.25 16.426 20.25 14V8C20.25 5.57397 19.7602 4.07086 18.8447 3.15533C17.9291 2.2398 16.426 1.75 14 1.75H8C5.57397 1.75 4.07086 2.2398 3.15533 3.15533Z" fill="#101020"/>
        </svg>
    );
    return (
        <Icon component={svg} {...props} />
    );
}