import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function BackIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    
    const fillStyle = {fill: 'currentColor'}

    const svg = () => (
        <svg width={props.width?props.width:"1em"} height={props.height?props.height:"1em"} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={fillStyle} d="M7.50016 0.333313C3.82016 0.333313 0.833496 3.31998 0.833496 6.99998C0.833496 10.68 3.82016 13.6666 7.50016 13.6666C11.1802 13.6666 14.1668 10.68 14.1668 6.99998C14.1668 3.31998 11.1802 0.333313 7.50016 0.333313ZM9.8335 7.49998H6.3735L7.52016 8.64665C7.7135 8.83998 7.7135 9.15998 7.52016 9.35331C7.42016 9.45331 7.2935 9.49998 7.16683 9.49998C7.04016 9.49998 6.9135 9.45331 6.8135 9.35331L4.8135 7.35331C4.62016 7.15998 4.62016 6.83998 4.8135 6.64665L6.8135 4.64665C7.00683 4.45331 7.32683 4.45331 7.52016 4.64665C7.7135 4.83998 7.7135 5.15998 7.52016 5.35331L6.3735 6.49998H9.8335C10.1068 6.49998 10.3335 6.72665 10.3335 6.99998C10.3335 7.27331 10.1068 7.49998 9.8335 7.49998Z" fill={props.style?.color?props.style.color:"#101020"}/>
        </svg>
    );

    return (
        <Icon component={svg} {...props} />
    );
}