import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function MinerLineIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
  
  const fillStyle = {fill: 'currentColor'}

  const Svg = () => (

    <svg width={(props.style?.width)?props.style.width:"1em"} height={(props.style?.height)?props.style.height:"1em"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0001 8.53333C10.0001 8.3282 10.0137 8.12308 10.0274 7.90427V7.84957C10.0548 7.58974 10.0821 7.34359 10.1231 7.08376C10.1231 7.05641 10.1368 7.01538 10.1368 6.97436C10.1778 6.74188 10.2325 6.52308 10.2872 6.2906L10.3283 6.1265C10.383 5.92137 10.4513 5.72991 10.5334 5.53846C10.5607 5.47009 10.5881 5.38803 10.6154 5.31966C10.6838 5.14188 10.7659 4.97778 10.8479 4.8C10.8889 4.71795 10.93 4.6359 10.971 4.54017C11.0531 4.37607 11.1488 4.22564 11.2445 4.07521C11.2992 3.99316 11.3402 3.89744 11.3949 3.81538C11.5043 3.65128 11.6137 3.50085 11.7368 3.35043C11.7778 3.29573 11.8052 3.2547 11.8462 3.2L11.8052 3.15897C11.436 2.78974 11.436 2.18803 11.8052 1.8188C12.1744 1.44957 12.7761 1.44957 13.1454 1.8188L13.1864 1.85983C14.5266 0.793162 16.1539 0.150427 17.8633 0.0273501C18.1642 -3.53903e-07 18.4787 0 18.7795 0L18.7659 0.451282L18.9436 1.84615C17.5625 2.24273 16.2907 2.9265 15.183 3.84273L19.7231 8.38291C20.0924 8.75214 20.0924 9.35385 19.7231 9.72308C19.3539 10.0923 18.7522 10.0923 18.383 9.72308L13.8428 5.18291C12.9266 6.2906 12.2428 7.56239 11.8462 8.94359L10.0001 8.69744V8.49231V8.53333Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
      <path d="M4.00006 5.5C4.00006 6.32843 3.32849 7 2.50006 7C1.67163 7 1.00006 6.32843 1.00006 5.5C1.00006 4.67157 1.67163 4 2.50006 4C3.32849 4 4.00006 4.67157 4.00006 5.5Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
      <path d="M9.00006 2C9.00006 2.55228 8.55234 3 8.00006 3C7.44777 3 7.00006 2.55228 7.00006 2C7.00006 1.44772 7.44777 1 8.00006 1C8.55234 1 9.00006 1.44772 9.00006 2Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
      <path d="M19.0001 14C19.0001 14.5523 18.5523 15 18.0001 15C17.4478 15 17.0001 14.5523 17.0001 14C17.0001 13.4477 17.4478 13 18.0001 13C18.5523 13 19.0001 13.4477 19.0001 14Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5771 12.9232C15.2666 12.4265 14.5977 12.3018 14.1291 12.6532L11.8871 14.3347C11.4127 14.6905 10.7347 14.5577 10.4296 14.0492L7.23732 8.72876C6.35819 7.26356 4.1653 7.50427 3.62496 9.12529L0.438802 18.6838C0.222958 19.3313 0.704928 20 1.38749 20H18.1958C18.9812 20 19.4601 19.136 19.0438 18.47L15.5771 12.9232ZM17.2937 18.5L14.5936 14.1798L12.7871 15.5347C11.6011 16.4242 9.90608 16.0922 9.14336 14.821L5.95108 9.5005C5.73129 9.1342 5.18307 9.19438 5.04799 9.59964L2.0812 18.5H17.2937Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
    </svg>
  );

    return (
        <Icon component={Svg} {...props} />
    );
}

