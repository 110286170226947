import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

export function AccountLineIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    
    const fillStyle = {fill: 'currentColor'}

    const Svg = () => (
        <svg width={props.width?props.width:"1em"} height={props.height?props.height:"1em"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={fillStyle} fill={props.style?.color?props.style.color:"#101020"} fillRule="evenodd" clipRule="evenodd" d="M6.96973 8.50998C6.96973 6.28726 8.76402 4.47998 10.9997 4.47998C13.2239 4.47998 15.0297 6.28577 15.0297 8.50998V8.51422C15.0175 10.6826 13.3119 12.4557 11.1453 12.5295C11.1013 12.531 11.0572 12.5287 11.0137 12.5224C11.018 12.5231 11.0188 12.523 11.016 12.5228C11.0147 12.5227 11.013 12.5227 11.0108 12.5226C11.0087 12.5225 11.0063 12.5225 11.0035 12.5225C10.9912 12.5225 10.9798 12.5233 10.9728 12.5242C10.9334 12.5291 10.8938 12.5309 10.8542 12.5295C8.69134 12.4558 6.96973 10.6854 6.96973 8.50998ZM10.9997 5.97998C9.59543 5.97998 8.46973 7.1127 8.46973 8.50998C8.46973 9.85876 9.52336 10.9585 10.8584 11.0284C10.9504 11.0209 11.0484 11.0202 11.1437 11.0282C12.473 10.9572 13.521 9.86176 13.5297 8.50786C13.5286 7.11305 12.3948 5.97998 10.9997 5.97998Z" />
            <path style={fillStyle} fill={props.style?.color?props.style.color:"#101020"} fillRule="evenodd" clipRule="evenodd" d="M10.9242 21.7497C5.02204 21.709 0.25 16.9118 0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.4844 17.643 21.0095 12.3368 21.6677C11.8988 21.722 11.4527 21.75 11 21.75C10.9999 21.75 11.0001 21.75 11 21.75C10.9748 21.75 10.9494 21.7499 10.9242 21.7497ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 13.2737 19.4297 15.3557 18.0688 16.9664C17.7065 16.287 17.1309 15.6773 16.3885 15.1778L16.3869 15.1767C14.8771 14.1665 12.9231 13.685 11.0073 13.685C9.09117 13.685 7.13336 14.1666 5.6148 15.1753L5.61107 15.1778C4.86881 15.6772 4.2932 16.2868 3.93088 16.9661C2.57022 15.3555 1.75 13.2735 1.75 11ZM10.9436 20.2498C10.0367 20.2444 9.16081 20.1085 8.33367 19.8599C7.12631 19.4965 6.02373 18.8918 5.07815 18.1C5.2459 17.5359 5.67642 16.9422 6.44661 16.4235C7.66787 15.613 9.31923 15.185 11.0073 15.185C12.696 15.185 14.3416 15.6133 15.5519 16.4228C16.3227 16.9417 16.7536 17.5356 16.9214 18.1C15.3636 19.4045 13.3798 20.201 11.2033 20.2478C11.1356 20.2493 11.0678 20.25 10.9998 20.25C10.981 20.25 10.9623 20.2499 10.9436 20.2498Z"/>
        </svg>
    );

    return (
        <Icon component={Svg} {...props} />
    );
}