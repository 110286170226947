import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const fillStyle = {fill: 'currentColor'}

const Svg = () => (

    <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 1C12 0.447715 11.5523 0 11 0C10.4477 0 10 0.447715 10 1V1.08C10 1.63228 10.4477 2.08 11 2.08C11.5523 2.08 12 1.63228 12 1.08V1Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
        <path d="M4.56711 3.15289C4.17658 2.76237 3.54342 2.76237 3.15289 3.15289C2.76237 3.54342 2.76237 4.17658 3.15289 4.56711L3.28289 4.69711C3.67342 5.08763 4.30658 5.08763 4.69711 4.69711C5.08763 4.30658 5.08763 3.67342 4.69711 3.28289L4.56711 3.15289Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
        <path d="M18.8471 4.56711C19.2376 4.17658 19.2376 3.54342 18.8471 3.15289C18.4566 2.76237 17.8234 2.76237 17.4329 3.15289L17.3029 3.28289C16.9124 3.67342 16.9124 4.30658 17.3029 4.69711C17.6934 5.08763 18.3266 5.08763 18.7171 4.69711L18.8471 4.56711Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
        <path d="M1 10C0.447715 10 0 10.4477 0 11C0 11.5523 0.447715 12 1 12H1.08C1.63228 12 2.08 11.5523 2.08 11C2.08 10.4477 1.63228 10 1.08 10H1Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
        <path d="M20.92 10C20.3677 10 19.92 10.4477 19.92 11C19.92 11.5523 20.3677 12 20.92 12H21C21.5523 12 22 11.5523 22 11C22 10.4477 21.5523 10 21 10H20.92Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
        <path d="M4.69711 18.7171C5.08763 18.3266 5.08763 17.6934 4.69711 17.3029C4.30658 16.9124 3.67342 16.9124 3.28289 17.3029L3.15289 17.4329C2.76237 17.8234 2.76237 18.4566 3.15289 18.8471C3.54342 19.2376 4.17658 19.2376 4.56711 18.8471L4.69711 18.7171Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
        <path d="M18.7171 17.3029C18.3266 16.9124 17.6934 16.9124 17.3029 17.3029C16.9124 17.6934 16.9124 18.3266 17.3029 18.7171L17.4329 18.8471C17.8234 19.2376 18.4566 19.2376 18.8471 18.8471C19.2376 18.4566 19.2376 17.8234 18.8471 17.4329L18.7171 17.3029Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
        <path d="M12 20.92C12 20.3677 11.5523 19.92 11 19.92C10.4477 19.92 10 20.3677 10 20.92V21C10 21.5523 10.4477 22 11 22C11.5523 22 12 21.5523 12 21V20.92Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11 3.75C6.99594 3.75 3.75 6.99594 3.75 11C3.75 15.0041 6.99594 18.25 11 18.25C15.0041 18.25 18.25 15.0041 18.25 11C18.25 6.99594 15.0041 3.75 11 3.75ZM5.25 11C5.25 7.82436 7.82436 5.25 11 5.25C14.1756 5.25 16.75 7.82436 16.75 11C16.75 14.1756 14.1756 16.75 11 16.75C7.82436 16.75 5.25 14.1756 5.25 11Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
    </svg>


);

let prop:any = undefined

export function PvCapacityLineIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={Svg} {...props} />
    );
}