import HttpUtils from '../utils/HttpUtils'

async function getBlockList(data?:any) {
    const params= {
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getBlockList',params);
}

async function getBlockByHeight(height:number) {
    const params= {
        height:height
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getBlockByHeight',params);
}


const BlockApi ={
    getBlockList:getBlockList,
    getBlockByHeight:getBlockByHeight,
}

export default BlockApi;