import React,{memo} from 'react';
import {Area,AreaConfig} from '@ant-design/plots';
import { ListItem } from '@antv/component';
import moment from 'moment'
import { Skeleton } from 'antd';
import {formatNumberValue} from '../utils/DataFormatUtils'


type cardProps = {
    title?: string,
    data: any,
    xField: string,
    yField: string,
    seriesField:string,
    lineColor:string|string[],
    areaStyle?:string,
    height:string,
}

function ArkreenStackAreaChart(prop: cardProps): JSX.Element {
    const data = prop.data;
    const config:AreaConfig = {
        data,
        smooth: true,
        xField: prop.xField,
        yField: prop.yField,
        seriesField:prop.seriesField,
        xAxis: {
            range: [0, 1],
            tickCount: 6,
            label: {
                formatter: (text: string, item: ListItem, index: number) => {
                    if(index === 0 || index === data.length-1) {
                        return null
                    }
                    else {
                        return moment(text, "YYYY-MM-DD").format("ddd DD")
                    }
                }
            },
            line: null,
            tickLine: null,
        },
        yAxis: {
                range: [0, 1],
                grid: null,
                position: "right",
                label: {
                    formatter: (text: string, item: ListItem, index: number) => {
                        if(index === 0) {
                            return null
                        }else {
                            return formatNumberValue(Number(text),0);
                        }
                    }
                },
        },
        autoFit:true,
        isStack:false,
        line: {color: prop.lineColor},
        color: (type:any) => {
            let color = '#00913A';
            if(type.seriesField === 'Reward'){
                color = '#1EAAF9';
            }else if(type.seriesField === 'Energy'){
                color = '#00913A';
            }
            return color;
        },
        areaStyle: (e:any) => {
            if(e.seriesField === "Reward") {
                // 蓝色渐变
                return {fill: 'l(270) 0:#ffffff 0.4:#1EAAF9 1:#1EAAF9'}
            }else if(e.seriesField === "Energy") {
                // 蓝色渐变
                return {fill: 'l(270) 0:#ffffff 0.5:#00913A 1:#00913A'}
            }else {
                // 绿色渐变
                return {fill: 'l(270) 0:#ffffff 0.5:#00913A 1:#00913A'}
            }
        },
        tooltip:{
            formatter:(datum: any) => {
                if(datum.seriesField === "Reward"){
                    return { name: datum.seriesField, value: Number(Number(datum.value).toFixed(2)).toLocaleString() + ' tAKRE' };
                }else if(datum.seriesField === " Reward"){
                    return { name: datum.seriesField, value: Number(Number(datum.value).toFixed(2)).toLocaleString() + ' tAKRE' };
                }else if(datum.seriesField === "Energy"){
                    return { name: datum.seriesField, value: Number(datum.value).toLocaleString() + ' Wh' };
                }else{
                    return { name: datum.seriesField, value: datum.value  };
                }
            }
        },
        legend:{
            position: "top-right",
            padding: [0,0,0,0]
        }
      };
      
    return (
        <div>
            {prop.title?<div className='detail-info-box-header' style={{paddingBlockEnd:'12px'}}><span>{prop.title}</span></div>:''}
            {(prop.data && prop.data.length>0)?<Area style={{height:prop.height}} {...config} />:<Skeleton.Input style={{height:'192px'}} block={true} active size='small' />}
        </div>
    )
}

export default memo(ArkreenStackAreaChart);
    