import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function MakerIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.53974 4.29427C2.53249 2.38328 4.08433 0.833252 5.99307 0.833252C7.90255 0.833252 9.45307 2.38378 9.45307 4.29325C9.45307 6.16296 7.97257 7.68388 6.12327 7.7463C6.10104 7.74705 6.07879 7.74632 6.05665 7.7441C6.02451 7.74089 5.97647 7.74046 5.93167 7.74453C5.91104 7.74641 5.89031 7.747 5.86961 7.7463C4.01314 7.6839 2.54027 6.16272 2.53974 4.29427ZM5.99307 1.83325C4.63548 1.83325 3.5342 2.93601 3.53973 4.29121L3.53974 4.29325C3.53974 5.61527 4.57246 6.68791 5.87633 6.74582C5.95538 6.74032 6.0389 6.74005 6.11769 6.74576C7.41529 6.68714 8.45307 5.61401 8.45307 4.29325C8.45307 2.93606 7.35026 1.83325 5.99307 1.83325Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path d="M9.5 11.6666C9.5 11.3904 9.27614 11.1666 9 11.1666C8.72386 11.1666 8.5 11.3904 8.5 11.6666V12.4999H7.66667C7.39052 12.4999 7.16667 12.7238 7.16667 12.9999C7.16667 13.2761 7.39052 13.4999 7.66667 13.4999H8.5V14.3333C8.5 14.6094 8.72386 14.8333 9 14.8333C9.27614 14.8333 9.5 14.6094 9.5 14.3333V13.4999H10.3333C10.6095 13.4999 10.8333 13.2761 10.8333 12.9999C10.8333 12.7238 10.6095 12.4999 10.3333 12.4999H9.5V11.6666Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path d="M2.93141 10.1221C3.7486 9.57537 4.85608 9.28658 5.99003 9.28658C7.12397 9.28658 8.23145 9.57537 9.04865 10.1221C9.27815 10.2757 9.58869 10.2141 9.74225 9.98463C9.89581 9.75512 9.83425 9.44458 9.60474 9.29102C8.5886 8.61113 7.27608 8.28658 5.99003 8.28658C4.7042 8.28658 3.39193 8.61101 2.37586 9.29066C1.46515 9.89674 0.943359 10.7464 0.943359 11.6607C0.943359 12.575 1.46499 13.4261 2.37522 14.0354L2.37601 14.0359C3.39492 14.7152 4.70361 15.0399 5.99336 15.0399C6.2695 15.0399 6.49336 14.8161 6.49336 14.5399C6.49336 14.2638 6.2695 14.0399 5.99336 14.0399C4.85663 14.0399 3.75217 13.7514 2.93112 13.2042C2.22829 12.7335 1.94336 12.1648 1.94336 11.6607C1.94336 11.1569 2.22803 10.5901 2.93031 10.1229L2.93141 10.1221Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>

    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}