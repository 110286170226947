import React,{ useState  } from 'react';
import { Layout,Input,Space,Divider,message  } from 'antd';
import {SearchIcon} from '../icons/SearchIcon'
import ArkreenButton from '@/components/ArkreenButton';
import './css/PageTop.css'
import {MenuIcon} from '../icons/MenuIcon'
import {ArkreenLogoSingleIcon} from '../icons/ArkreenLogoSingleIcon'
import {ArkreenLogoTextUnionIcon} from '../icons/ArkreenLogoTextUnionIcon'
import {CloseMenuIcon} from '../icons/CloseMenuIcon'
import {AccountLineIcon} from '../icons/AccountLineIcon'
import {MinerLineIcon} from '../icons/MinerLineIcon'
import {DashboardLineIcon} from '../icons/DashboardLineIcon'
import {TransactionLineIcon} from '../icons/TransactionLineIcon'
import {BlockLineIcon} from '../icons/BlockLineIcon'
import {ArkreenHomeIcon} from '../icons/ArkreenHomeIcon'
import {ArkreenConsoleIcon} from '../icons/ArkreenConsoleIcon'
import {ArkreenDocsIcon} from '../icons/ArkreenDocsIcon'
import {useNavigate } from 'react-router-dom'
import StatApi from '../api/StatApi'
import {jumpTo} from '../utils/BrowserUtils'
const { Header } = Layout;

function AppTopBar(){

  const search_style: React.CSSProperties = {
    display:'none'
  };

  function showMenu(){
    const style: React.CSSProperties = {
      position:'absolute',
      top:'64px',
      background:'#FFFFFF',
      width:'100%',
      height:'-webkit-fill-available',
      color:'#000000',
      zIndex:'999',
      display:menuStyle.display==='none'?'block':'none',
    };
    setMenuStyle(style)
  }

  const menu_style: React.CSSProperties = {
    position:'absolute',
    top:'64px',
    background:'#FFFFFF',
    width:'100%',
    height:'-webkit-fill-available',
    color:'#000000',
    zIndex:'999',
    display:'none'
  };

  const [menuStyle,setMenuStyle] = useState(menu_style)
  const [searchStyle,setSearchStyle] = useState(search_style)
  const navigate  = useNavigate()
  function gotoHome(){
    if(menuStyle.display!=='none'){
      showMenu();
    }
    navigate('/')
  }

  const menuList = [];
  const dashboard = {
    key: '',
    sort: '1',
    icon: <DashboardLineIcon/>,
    label: 'Dashboard',
    url: '/'
  }
  const accounts = {
    key: 'accounts',
    sort: '2',
    icon: <AccountLineIcon/>,
    label: 'Accounts',
    url: '/accounts'
  }
  const miners = {
    key: 'miners',
    sort: '3',
    icon: <MinerLineIcon/>,
    label: 'Miners',
    url: '/miners'
  }
  const transactions = {
    key: 'transactions',
    sort: '4',
    icon: <TransactionLineIcon />,
    label: 'Transactions',
    url: '/txs'
  }
  const blocks = {
    key: 'blocks',
    sort: '5',
    icon: <BlockLineIcon/>,
    label: 'Blocks',
    url: '/blocks',
  }
  menuList.push(dashboard)
  menuList.push(accounts)
  menuList.push(miners)
  menuList.push(transactions)
  menuList.push(blocks)

  function itemOnClick(key: string,url: string) {
    navigate(url)
    showMenu();
  }
  const [searchValue,setSearchValue] = useState<any>(undefined)

  let isSearch = false

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>){
    if(e.code === 'Enter'){
      if(!isSearch){
        search(searchValue.target.value)
        closeSearch()
      }
    }
  }

  async function search(value:string){
    isSearch = true;
    try{
      const rsp:any = await StatApi.search(value);
      if(!rsp.error){
        message.success("jump to result page");
        const type = rsp.result.type;
        if(type === 0){
          navigate('/tx/'+value)
        }else if(type === 1){
          navigate('/block/'+value)
        }else if(type === 2){
          navigate('/account/'+value)
        }else if(type === 3){
          navigate('/miner/'+value)
        }else if(type === 4){
          navigate('/block/'+rsp.result.data.height)
        }
      }else{
        message.error(rsp.error.message);
      }
    }catch(err){
      message.error("search error,please try again");
    }
    isSearch = false;
  }

  function showSearch(){
    const style: React.CSSProperties = {
      display:'block'
    };
    setSearchStyle(style)
  }

  function closeSearch(){
    const style: React.CSSProperties = {
      display:'none'
    };
    setSearchStyle(style)
  }

  return (
    <Header className='headerStyle'>
      <div className='top-menu-box-m'>
            <Space>
              <div onClick = {() => gotoHome()} style={{paddingLeft:'3px'}}>
                <ArkreenLogoSingleIcon /><ArkreenLogoTextUnionIcon style={{marginLeft:'10px'}}/>
              </div>
            </Space>
            <Space>
              <div style={{gap:'0px'}}>
                <ArkreenButton type='link' className='top-search-open' onClick={showSearch}><span><SearchIcon/></span></ArkreenButton> 
                <ArkreenButton type='link' className='top-menu-open' onClick={showMenu}><span >{menuStyle.display === 'none'?<MenuIcon/>:<CloseMenuIcon/>}</span></ArkreenButton> 
              </div>
            </Space>
      </div>
      <div className='top-menu-box-pc'>
            <Space>
              <Input onChange={setSearchValue} onKeyDown={e=> onKeyDown(e)} prefix={<SearchIcon style={{color:'#B7B7BC'}} />} allowClear className='top-search-box' placeholder="Search for an address, tx, block, token, name..." />
            </Space>
            <Space>
              <div style={{gap:'0px'}}>
                {/**<ArkreenButton className='top-price-btn'><span style={{color: 'black'}}>Price</span><span style={{color:'#00913A',paddingInline:'5px'}}>$1.00</span></ArkreenButton>  */}
              </div>
            </Space>
      </div>
      <div className='top-menu-show'>
        <div style={menuStyle}>
          <div className='mobile-menu-box'>
            {
              menuList.map((item) => {
                return <div key={item.key} onClick = {() => itemOnClick(item.key,item.url)}><div className='mobile-menu-icon'>{item.icon}</div><div className='mobile-menu-name'>{item.label}</div></div>
              })
            }
            <Divider />
            <div onClick = {() => jumpTo('https://arkreen.com/')}><div className='mobile-menu-icon'><ArkreenHomeIcon/></div><div className='mobile-menu-name'>Home</div></div>
            <div onClick = {() => jumpTo('https://testconsole.arkreen.com/')}><div className='mobile-menu-icon'><ArkreenConsoleIcon/></div><div className='mobile-menu-name'>Console</div></div>
            <div onClick = {() => jumpTo('https://docs.arkreen.com/')}><div className='mobile-menu-icon'><ArkreenDocsIcon/></div><div className='mobile-menu-name'>Docs</div></div>
          </div>
        </div>
      </div>
      <div style={searchStyle}>
        <div className='top-search-show' >
          <div style={{float:'left',marginTop:'-4px',flex:1}}>
            <Input onChange={setSearchValue} onKeyDown={e=> onKeyDown(e)} prefix={<SearchIcon style={{color:'#B7B7BC'}} />} allowClear className='top-search-box-m' placeholder="Search for an address, tx, block, token, name..." />
          </div>
          <div style={{float:'right',lineHeight: '64px'}}>
            <ArkreenButton type='link' className='top-menu-open' onClick={closeSearch}><span ><CloseMenuIcon/></span></ArkreenButton>
          </div>
        </div>
      </div>
    </Header>
  );
}

export default AppTopBar;
