import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function GitHubIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14px"} height={props.style?.height?props.style.height:"14px"} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.99371 0.504883C3.26349 0.504883 0.242188 3.5115 0.242188 7.22358C0.242188 10.1966 2.17481 12.7077 4.85854 13.5979C5.19612 13.6567 5.32271 13.4552 5.32271 13.2788C5.32271 13.1192 5.31427 12.5901 5.31427 12.0275C3.61795 12.3382 3.1791 11.6159 3.04407 11.238C2.96812 11.0448 2.63898 10.4486 2.35204 10.289C2.11574 10.163 1.77816 9.85227 2.3436 9.84388C2.87528 9.83548 3.25506 10.331 3.38165 10.5325C3.98928 11.5487 4.95982 11.2632 5.34803 11.0868C5.4071 10.6501 5.58433 10.3562 5.77844 10.1882C4.27622 10.0202 2.70649 9.44075 2.70649 6.87085C2.70649 6.14019 2.96812 5.53551 3.39852 5.0652C3.33101 4.89723 3.09471 4.20857 3.46604 3.28474C3.46604 3.28474 4.03148 3.10838 5.32271 3.97341C5.86283 3.82224 6.43671 3.74666 7.01059 3.74666C7.58447 3.74666 8.15835 3.82224 8.69847 3.97341C9.9897 3.09998 10.5551 3.28474 10.5551 3.28474C10.9265 4.20857 10.6902 4.89723 10.6227 5.0652C11.0531 5.53551 11.3147 6.13179 11.3147 6.87085C11.3147 9.44915 9.73652 10.0202 8.2343 10.1882C8.47905 10.3982 8.69003 10.8013 8.69003 11.4312C8.69003 12.3298 8.68159 13.0521 8.68159 13.2788C8.68159 13.4552 8.80818 13.6651 9.14576 13.5979C11.8126 12.7077 13.7452 10.1882 13.7452 7.22358C13.7452 3.5115 10.7239 0.504883 6.99371 0.504883Z" style={fillStyle} fill={props.style?.color?props.style.color:"#1D1D20"} />
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}