import HttpUtils from '../utils/HttpUtils'

async function getTransactionList(data?: any) {
    const params = {
        type:data?.type,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getTransactionListByType',params);
}

async function getMinerTransactionListByType(data?: any) {
    const params = {
        address:data.address,
        type:data?.type,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getMinerTransactionListByType',params);
}

async function getMinerReportList(data?: any) {
    const params = {
        address:data.address,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getMinerReportList',params);
}

async function getTransactionByHash(hash: string) {
    const params = {
        hash:hash
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getTransactionByHash',params);
}


async function getBlockTransactionsByHash(hash: string) {
    const params = {
        hash:hash
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getBlockTransactionsByHash',params);
}

async function getMinerRewardList(data: any) {
    const params = {
        address:data.address,
        offset:data?.offset?data.offset:1,
        limit:data?.limit?data.limit:25,
    }
    return await HttpUtils.sendJsonRPC('/v1','net_getMinerRewardList',params);
}


const TransactionApi ={
    getTransactionList:getTransactionList,
    getMinerTransactionListByType:getMinerTransactionListByType,
    getMinerReportList:getMinerReportList,
    getTransactionByHash:getTransactionByHash,
    getBlockTransactionsByHash:getBlockTransactionsByHash,
    getMinerRewardList:getMinerRewardList
}

export default TransactionApi;