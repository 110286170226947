import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function Maker2Icon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        
        <svg width={props.style?.width?props.style.width:"20px"} height={props.style?.height?props.style.height:"20px"}  viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99996 0C5.37996 0 3.24996 2.13 3.24996 4.75C3.24996 7.32 5.25996 9.4 7.87996 9.49C7.95996 9.48 8.03996 9.48 8.09996 9.49C8.11996 9.49 8.12996 9.49 8.14996 9.49C8.15996 9.49 8.15996 9.49 8.16996 9.49C10.73 9.4 12.74 7.32 12.75 4.75C12.75 2.13 10.62 0 7.99996 0Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M13.08 12.15C10.29 10.29 5.73996 10.29 2.92996 12.15C1.65996 13 0.959961 14.15 0.959961 15.38C0.959961 16.61 1.65996 17.75 2.91996 18.59C4.31996 19.53 6.15996 20 7.99996 20C9.83996 20 11.68 19.53 13.08 18.59C14.34 17.74 15.04 16.6 15.04 15.36C15.03 14.13 14.34 12.99 13.08 12.15ZM9.99996 16.13H8.74996V17.38C8.74996 17.79 8.40996 18.13 7.99996 18.13C7.58996 18.13 7.24996 17.79 7.24996 17.38V16.13H5.99996C5.58996 16.13 5.24996 15.79 5.24996 15.38C5.24996 14.97 5.58996 14.63 5.99996 14.63H7.24996V13.38C7.24996 12.97 7.58996 12.63 7.99996 12.63C8.40996 12.63 8.74996 12.97 8.74996 13.38V14.63H9.99996C10.41 14.63 10.75 14.97 10.75 15.38C10.75 15.79 10.41 16.13 9.99996 16.13Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}