import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function ArkreenLogoSingleIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const LogoSvg = () => (
        <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6819 12.5353L8.34781 19.4687C7.93156 20.1347 8.41031 21 9.19563 21H24.5788C25.4622 21 26.0009 20.0281 25.5328 19.2787L14.4841 1.60093C13.7984 0.504056 12.2028 0.504056 11.5153 1.60093L0.347814 19.4687C-0.0684361 20.1347 0.410314 21 1.19563 21H3.69281C3.82001 21 3.94511 20.9676 4.05636 20.906C4.1676 20.8443 4.26134 20.7554 4.32875 20.6475L12.6819 7.28124C12.7156 7.22725 12.7625 7.18273 12.8181 7.15186C12.8738 7.12099 12.9364 7.10479 13 7.10479C13.0636 7.10479 13.1262 7.12099 13.1819 7.15186C13.2375 7.18273 13.2844 7.22725 13.3181 7.28124L19.0328 16.425C19.0686 16.4818 19.0885 16.5471 19.0904 16.6142C19.0924 16.6813 19.0763 16.7477 19.0438 16.8064C19.0113 16.8651 18.9637 16.9141 18.9059 16.9481C18.848 16.9822 18.7821 17.0001 18.715 17H15.285C15.2182 16.9997 15.1527 16.9815 15.0953 16.9474C15.0379 16.9133 14.9906 16.8645 14.9583 16.806C14.9261 16.7475 14.9101 16.6815 14.9119 16.6147C14.9137 16.5479 14.9334 16.4829 14.9688 16.4262L15.2344 16C15.2716 15.9404 15.2914 15.8715 15.2914 15.8012C15.2914 15.731 15.2716 15.6621 15.2344 15.6025L13.3181 12.5366C13.2845 12.4825 13.2377 12.4379 13.1821 12.4069C13.1266 12.3759 13.064 12.3596 13.0003 12.3595C12.9367 12.3594 12.8741 12.3754 12.8184 12.4062C12.7626 12.4369 12.7157 12.4814 12.6819 12.5353Z" fill="#00913A"/>
        </svg>
    )
    //
    return (
        <Icon component={LogoSvg} {...props} />
    );
}