
import { useState,useEffect  } from "react";
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/DetailPage.css'
import '../common/css/ArkreenSelect.css'
import '../common/css/ArkreenMain.css'
import {formatVisualizationDate,formatTxTypeTag,formatLongString,formatDate} from '../utils/DataFormatUtils'
import {TransactionLineIcon} from '../icons/TransactionLineIcon'
import {ErrorPendingIcon} from '../icons/ErrorPendingIcon'
import {AgeIcon} from '../icons/AgeIcon'
import {Tooltip } from 'antd';
import {BackIcon} from '../icons/BackIcon'
import TransactionApi from '@/api/TransactionApi';
import BlockApi from '@/api/BlockApi';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate,useLocation } from 'react-router-dom'
import ArkreenDetailTd from '../components/ArkreenDetailTd'
import ArkreenTable from '../components/ArkreenTable'
import ArkreenInfoHeaderCard from '../components/ArkreenInfoHeaderCard'
import ArkreenButton from '../components/ArkreenButton'

function BlockDetail(){
  const navigate  = useNavigate()
  const location  = useLocation()

  interface DataType {
    hash: string;
    type: number;
    height: number;
    status:number;
    createTime: string;
  }

  const height =  Number(location.pathname.replace('/block/',''))
  const [blockHeight,setBlockHeight] = useState(height)
  const [isPending,setIsPending] = useState(false)
 
  const [blockInfo,setBlockInfo] = useState({
    height: undefined,
    hash: undefined,
    parentHash: undefined,
    merkleRoot: undefined,
    blockIntervalTime: undefined,
    createTime: undefined,
    txAmount: undefined
  })

  function getBlockByHeight() {
    setBlockHeight(height)
    BlockApi.getBlockByHeight(height).then((resp:any) => {
      if(!resp.error){
        setBlockInfo(resp.result)
        if(!resp.result.hash){
          setIsPending(true)
        }else{
          setIsPending(false)
        }
      }else{
        navigate("/404")
      }
    });
  }

  function goBack(){
    navigate(-1);
  }

  function showTxDetail(hash:string){
    navigate('/tx/'+hash)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Hash',
      dataIndex: 'hash',
      key: 'hash',
      width:'180px',
      fixed: 'left',
    },
    {
      title: 'Block',
      dataIndex: 'height',
      key: 'height',
      width:'120px'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width:'180px'
    },
    {
      title: 'Age',
      dataIndex: 'createTime',
      key: 'createTime',
      width:'180px'
    },
  ];

  const [transactionList,setTransactionList] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)

  async function getTransactions() {
    setLoading(true)
    const rsp:any = await TransactionApi.getBlockTransactionsByHash(""+blockInfo.hash);
    if(!rsp.error){
      rsp.result.forEach((item: any,index: number) => {
        const hash = item.hash
          rsp.result[index].key = hash
          rsp.result[index].hash = <span onClick={() => {showTxDetail(hash)}} className="content-td-link">{formatLongString(hash,7,7)}</span>
          rsp.result[index].height = <span>{Number(item.height).toLocaleString()}</span>
          rsp.result[index].type = <span>{formatTxTypeTag(item.type)}</span>
          rsp.result[index].createTime = <span>{formatVisualizationDate(item.createTime)}</span>
      })
      setTransactionList(rsp.result)
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    getBlockByHeight()
  },[location.pathname])//eslint-disable-line
  useEffect(() => {
    getTransactions()
  },[blockInfo])//eslint-disable-line


  return (
    <div className='arkreen-main'>
      {
        (isPending)?(
          <div className='arkreen-404'>
              <ErrorPendingIcon />
              <div className='arkreen-404-title'>Oh oh!</div>
              <div className='arkreen-404-info'>This block is being packaged,please wait...</div>
              <div className='arkreen-404-btn'><ArkreenButton onClick={() => goBack()} className='arkreen-404-back'><BackIcon/> Go back </ArkreenButton></div>
          </div>
        ):(
          <div>
            <div className='detail-header'>
              <ArkreenInfoHeaderCard title='Block' value={blockInfo.hash} tag={Number(blockHeight).toLocaleString()}/>
              <div style={{paddingBlockStart:'20px'}}>
                <ArkreenDetailTd icon={<TransactionLineIcon style={{width:'14.33px',height:'14.33px'}}/>} title="Transactions" content={blockInfo.txAmount} />
                <ArkreenDetailTd icon={<AgeIcon style={{width:'14.33px',height:'14.33px'}}/>} title="Age" content={blockInfo.createTime?<Tooltip placement="right" title={formatDate(blockInfo.createTime?blockInfo.createTime*1000:0,'yyyy-MM-dd HH:mm:ss')}>{formatVisualizationDate(Number(blockInfo.createTime))}</Tooltip>:''} />
              </div>
            </div>
            <div className='detail-table-box'>
              <div style={{fontSize:'16px',lineHeight:'32px',paddingBlockEnd:'10px',marginTop:'-6px'}}><span>Transaction</span></div>
              <div className='detail-no-tab'>
                <ArkreenTable alwaysHidePagination={true} scroll={{ x: 660 }} currentPage={currentPage} columns={columns} datalist={transactionList} loading={loading} onPageChange={setCurrentPage}/>
              </div>
            </div>
          </div>
        )
      }
      
    </div>
  );
}

export default BlockDetail;