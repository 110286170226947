import * as React from "react";
import { Button, Result } from 'antd';

export class ErrorBoundary extends React.Component<Props> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  override state: State = { error: undefined };

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error, errorInfo);
  }

  override render() {
    const { error } = this.state;

    if (!error) {
      return this.props.children;
    }

    return (
      <Result
        style={{
          marginTop:'100px'
        }}
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary">Back Home</Button>}
    />
    );
  }
}

type Props = {
  children: React.ReactNode;
};

type State = {
  error: (Error & { status?: number }) | undefined;
};
