import React,{memo} from "react";
import { Skeleton } from 'antd';
import '../common/css/ArkreenGrowthTrendCard.css'
import ArkreenAreaChart from './ArkreenAreaChart'
import {FullIcon} from '../icons/FullIcon'
import { useNavigate } from 'react-router-dom'

type cardProps = {
    icon?:React.ReactNode,
    title: string,
    value: string | number,
    href?: string,
    time: string | number,
    data: any,
    height?:string,
    xField?:string,
    yField?:string,
    unit?:string,
    type?:number,
    lineColor?:string,
    fullButton?:boolean,
    routerLink?:string
}
//

//
function ArkreenGrowthTrendCard(prop: cardProps): JSX.Element {
    //
    const navigate  = useNavigate()

    function goto(){
        if(prop.routerLink){
            navigate(prop.routerLink)
        }
    }
    //
    return (
        <div className="growth-trend-card-body">
            <div className="growth-trend-card-title">
                <div style={{flex:8}}>
                    {(prop.title)?<span>{prop.icon}{prop.title}</span>:<Skeleton.Input style={{width:'200px'}}  active size='small' />}
                </div>
                <div style={{textAlign:'right',flex:2}}>
                    {prop.fullButton?<span style={{cursor:'pointer'}} onClick={() => goto()}><FullIcon/></span>:''}
                </div>
            </div>
            <div className="growth-trend-card-value">
                {(prop.value)?<span>{prop.value}</span>:<Skeleton.Input style={{width:'180px'}}  active size='small' />}
            </div>
            <div className="growth-trend-card-time">
                {(prop.time)?<span>{prop.time}</span>:<Skeleton.Input style={{width:'80px',height:'16px'}}  active size='small' />}
            </div>
            <div style={{height: prop.height?prop.height:'', marginTop: '8px'}}>
                {(prop.data && prop.data.length>0)?<ArkreenAreaChart unit={prop.unit} type={prop.type} height={prop.height?prop.height:'192px'} data={prop.data} xField={prop.xField?prop.xField:'date'} yField={prop.yField?prop.yField:'value'} lineColor={prop.lineColor?prop.lineColor:"#00913A"} />:<Skeleton.Input style={{height:prop.height?prop.height:'192px'}} block={true} active size='small' />}
            </div>
        </div>
    )
}

export default memo(ArkreenGrowthTrendCard);
    