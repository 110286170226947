import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function OwnerIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.31319 6.33991C5.31319 4.85809 6.50939 3.65324 7.99986 3.65324C9.48267 3.65324 10.6865 4.8571 10.6865 6.33991V6.34273C10.6784 7.78831 9.54131 8.97037 8.09689 9.01962C8.06756 9.02061 8.0382 9.01903 8.00915 9.01488C8.01202 9.01529 8.01258 9.01525 8.01074 9.01513C8.00986 9.01508 8.00869 9.01502 8.00726 9.01498C8.00586 9.01493 8.00422 9.01491 8.00236 9.01491C7.99416 9.01491 7.9866 9.01545 7.98188 9.01604C7.95566 9.01932 7.92923 9.02052 7.90282 9.01962C6.46093 8.97046 5.31319 7.79021 5.31319 6.33991ZM7.99986 4.65324C7.06366 4.65324 6.31319 5.40839 6.31319 6.33991C6.31319 7.23909 7.01561 7.97225 7.90563 8.01884C7.96694 8.01383 8.03232 8.01339 8.09582 8.01875C8.98201 7.9714 9.6807 7.24109 9.68652 6.33849C9.68576 5.40862 8.92991 4.65324 7.99986 4.65324Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.94952 15.1664C4.01473 15.1392 0.833374 11.9411 0.833374 7.99992C0.833374 4.04188 4.042 0.833252 8.00004 0.833252C11.9581 0.833252 15.1667 4.04188 15.1667 7.99992C15.1667 11.6562 12.4287 14.6729 8.89124 15.1117C8.59926 15.1479 8.30183 15.1666 8.00004 15.1666C7.99999 15.1666 8.00009 15.1666 8.00004 15.1666C7.98324 15.1666 7.9663 15.1665 7.94952 15.1664ZM1.83337 7.99992C1.83337 4.59416 4.59428 1.83325 8.00004 1.83325C11.4058 1.83325 14.1667 4.59416 14.1667 7.99992C14.1667 9.5157 13.6198 10.9037 12.7126 11.9775C12.4711 11.5246 12.0873 11.1181 11.5924 10.7851L11.5913 10.7844C10.5848 10.1109 9.28213 9.78992 8.00489 9.78992C6.72749 9.78992 5.42228 10.111 4.40991 10.7834L4.40742 10.7851C3.91258 11.1181 3.52884 11.5245 3.2873 11.9773C2.38019 10.9036 1.83337 9.51559 1.83337 7.99992ZM7.96241 14.1665C7.35785 14.1629 6.77392 14.0723 6.22249 13.9065C5.41758 13.6642 4.68253 13.2611 4.05214 12.7333C4.16398 12.3572 4.45099 11.9614 4.96445 11.6156C5.77862 11.0752 6.87953 10.7899 8.00489 10.7899C9.13072 10.7899 10.2278 11.0755 11.0346 11.6151C11.5485 11.9611 11.8357 12.357 11.9476 12.7333C10.9091 13.6029 9.58656 14.1339 8.13556 14.1651C8.09046 14.1661 8.04523 14.1666 7.99989 14.1666C7.98739 14.1666 7.97489 14.1665 7.96241 14.1665Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>

    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}