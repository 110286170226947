import React, { CSSProperties } from "react";
import { Skeleton  } from 'antd';
import '../common/css/DetailPage.css'

type cardProps = {
    icon:React.ReactNode,
    title: string,
    content: any,
    href?: string,
    style?:CSSProperties,
    titleColor?:string,
    valueColor?:string,
}

export default function ArkreenDetailTd(props: cardProps): JSX.Element {
    return (
        <div className='detail-header-info'>
            <div className='detail-header-info-title'>{props.icon} <span style={{marginLeft:'8px'}}>{props.title}</span></div>
            <div className='detail-header-info-value'>{(props.content===undefined || props.content==='')?<Skeleton.Input style={{width:'200px'}} active block={true} size='small' />:props.content}</div>
        </div>
    )
}
    