import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const fillStyle = {fill: 'currentColor'}

const Svg = () => (

  <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14.6339 8.38388C15.122 7.89573 15.122 7.10427 14.6339 6.61612C14.1457 6.12796 13.3543 6.12796 12.8661 6.61612L8.75 10.7322L7.13388 9.11612C6.64573 8.62796 5.85427 8.62796 5.36612 9.11612C4.87796 9.60427 4.87796 10.3957 5.36612 10.8839L7.86612 13.3839C8.35427 13.872 9.14573 13.872 9.63388 13.3839L14.6339 8.38388Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
  </svg>

);

let prop:any = undefined

export function SuccessIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={Svg} {...props} />
    );
}

