import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const svg = () => (
    <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0.75C0.585786 0.75 0.25 1.08579 0.25 1.5C0.25 1.91421 0.585786 2.25 1 2.25H17C17.4142 2.25 17.75 1.91421 17.75 1.5C17.75 1.08579 17.4142 0.75 17 0.75H1Z" fill={prop.color?prop.color:"#222432"}/>
        <path d="M0.300122 7.72986C0.26775 7.81368 0.25 7.90477 0.25 8C0.25 8.10929 0.273376 8.21312 0.315401 8.30676C0.343251 8.36894 0.379997 8.4281 0.425637 8.48232C0.441041 8.50065 0.457307 8.51822 0.47437 8.53499L3.29807 11.3587C3.59096 11.6516 4.06583 11.6516 4.35873 11.3587C4.65162 11.0659 4.65163 10.591 4.35874 10.2981L2.81068 8.75H17C17.4142 8.75 17.75 8.41421 17.75 8C17.75 7.58579 17.4142 7.25 17 7.25H2.8106L4.35874 5.70183C4.65163 5.40894 4.65162 4.93406 4.35873 4.64117C4.06583 4.34828 3.59096 4.34829 3.29807 4.64118L0.469665 7.46963C0.393174 7.54613 0.33666 7.63503 0.300122 7.72986Z" fill={prop.color?prop.color:"#222432"}/>
        <path d="M0.25 14.5C0.25 14.0858 0.585786 13.75 1 13.75H17C17.4142 13.75 17.75 14.0858 17.75 14.5C17.75 14.9142 17.4142 15.25 17 15.25H1C0.585786 15.25 0.25 14.9142 0.25 14.5Z" fill={prop.color?prop.color:"#222432"}/>
    </svg>

);

let prop:any = undefined

export function ArkreenMenuSwitchLeftIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={svg} {...props} />
    );
}