import { useState,useEffect  } from "react";
import { useLocation,useNavigate } from 'react-router-dom'
import { Col, Row } from 'antd';
import StatApi from '../api/StatApi'
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMain.css'
import moment from 'moment'
import {LeftIcon} from '../icons/LeftIcon'
import ArkreenStatGrowthChart from "@/components/ArkreenStatGrowthChart";


function StatGrowth(){
  //
  const height = '77vh'
  const location  = useLocation()
  const navigate = useNavigate()
  const type = location.pathname.replace('/stat/','')
  //
  const [minerGrowthData, setMinerGrowthData] = useState<any>([]);
  const [pvCapacityGrowthData, setPVCapacityGrowthData] = useState<any>([]);
  const [greenEnergyData, setGreenEnergyData] = useState<any>([]);
  const [CO2, setCO2] = useState<any>([]);
  const [deviceDaily, setDeviceDaily] = useState<any>({
    miner:undefined,
    capacity:undefined,
    energy:undefined,
    time:undefined,
    co2:undefined,
  });
  //
  function handleStatDeviceDaily(){
    StatApi.statDeviceDaily().then((rsp:any) => {
      if(!rsp.error){
        const MinerGrowth:any[] = []
        const Capacity:any[] = []
        const Energy:any[]  = []
        const CO2:any[]  = []
        const deviceDaily = {
          miner:'',
          capacity:'',
          energy:'',
          time:'',
          co2:''
        }
        let isKwh = false;
        let isKg = false;
        rsp.result.forEach((item:any) => {
          MinerGrowth.push({date:item.dataDate,value:item.minerTotal});
          Capacity.push({date:item.dataDate,value:item.ratedPowerTotal/ 1000 / 1000});
         // Energy.push({date:item.dataDate,value:item.energyGenerationTotal / 1000 / 1000});
          deviceDaily.miner = Number(item.minerTotal).toLocaleString()
          deviceDaily.capacity = Number(Number(item.ratedPowerTotal/1000/1000).toFixed(1)).toLocaleString()+" kW";
          //deviceDaily.energy = Number(Number(item.energyGenerationTotal/1000/1000).toFixed(2)).toLocaleString()+" kWh";
          const energy = item.energyGenerationTotal/1000/1000
          if(energy<10000){
            Energy.push({date:item.dataDate,value:energy});
            deviceDaily.energy = Number(Number(energy).toFixed(2)).toLocaleString()+" kWh";
            isKwh = true
          }else {
            if(isKwh){
              Energy.push({date:item.dataDate,value:energy});
            }else{
              Energy.push({date:item.dataDate,value:energy/1000});
            }
            deviceDaily.energy = Number(Number(energy/1000).toFixed(2)).toLocaleString()+" MWh";
          }
          const timeArr = moment(item.dataDate).format('LLLL').split(",");
          deviceDaily.time = timeArr[0]+","+timeArr[1]
          //const co = Number((item.energyGenerationTotal/1000/1000)*7.09/10000).toFixed(2);
          const co = Number((item.energyGenerationTotal/1000/1000)*7.09/10000);
          if(co<10){
            const temp = Number(co*1000).toFixed(2)
            deviceDaily.co2 = Number(temp).toLocaleString()+" kg "
            CO2.push({date:item.dataDate,value:temp})
            isKg = true
          }else {
            if(isKg){
              CO2.push({date:item.dataDate,value:Number(co*1000).toFixed(2)})
            }else{
              CO2.push({date:item.dataDate,value:co})
            }
            deviceDaily.co2 = Number(Number(co).toFixed(2)).toLocaleString()+" ton "
          }
        });
        setMinerGrowthData(MinerGrowth);
        setPVCapacityGrowthData(Capacity);
        setGreenEnergyData(Energy);
        setDeviceDaily(deviceDaily);
        setCO2(CO2);
      }
    })
  }
  
  useEffect(() => {
    handleStatDeviceDaily();
  },[])
  

  return (
    <div className="arkreen-main">
      <Row gutter={[16,16]} style={{paddingTop:'0'}}>
        <Col className="gutter-row" span={24}>
          <div className="back-btn"><div onClick={() => navigate('/')} style={{fontSize:'20px'}}><LeftIcon /></div><div onClick={() => navigate('/')} style={{lineHeight:'24px'}}> Back</div></div>
        </Col>
        <Col className="gutter-row" span={24}>
          {type==='miner'?<ArkreenStatGrowthChart height={height} title='Miner Growth' value={deviceDaily.miner} time={deviceDaily.time} data={minerGrowthData}/>:''}
          {type==='capacity'?<ArkreenStatGrowthChart type={1} height={height} title='PV Capacity Growth' value={deviceDaily.capacity} time={deviceDaily.time} data={pvCapacityGrowthData}/>:''}
          {type==='energy'?<ArkreenStatGrowthChart type={2} height={height} title='Green Energy Data Uploaded' value={deviceDaily.energy} time={deviceDaily.time} data={greenEnergyData}/>:''}
          {type==='impact'?<ArkreenStatGrowthChart type={3} height={height} title='Total Impact' value={deviceDaily.co2} time={deviceDaily.time} data={CO2}/>:''}
        </Col>
      </Row>
    </div>
  );
}

export default StatGrowth;
