import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function StatusIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.41341 6.02014C9.60867 5.82488 9.60867 5.50829 9.41341 5.31303C9.21815 5.11777 8.90156 5.11777 8.7063 5.31303L6.39319 7.62615L5.74674 6.9797C5.55148 6.78444 5.2349 6.78444 5.03964 6.9797C4.84437 7.17496 4.84437 7.49154 5.03964 7.68681L6.03964 8.68681C6.2349 8.88207 6.55148 8.88207 6.74674 8.68681L9.41341 6.02014Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.78683 0.833252C2.08985 0.833252 0.713501 2.21795 0.713501 3.90659V13.2999C0.713501 14.0104 0.972388 14.6382 1.52143 14.9616C2.07009 15.2847 2.74516 15.2075 3.36801 14.8646L3.36958 14.8637L6.6237 13.0566C6.70486 13.0113 6.84252 12.9749 7.006 12.9749C7.16998 12.9749 7.30396 13.0115 7.37967 13.0546L10.6374 14.8637C11.2596 15.2101 11.9356 15.2905 12.4856 14.9666C13.0355 14.6427 13.2935 14.0124 13.2935 13.2999L13.2935 3.90659L13.2935 3.90423C13.2855 2.2179 11.9109 0.833252 10.2135 0.833252H3.78683ZM1.7135 3.90659C1.7135 2.76855 2.64382 1.83325 3.78683 1.83325H10.2135C11.3557 1.83325 12.2876 2.76805 12.2935 3.90792V13.2999C12.2935 13.7941 12.1215 14.0205 11.9781 14.1049C11.8349 14.1892 11.5539 14.2295 11.1229 13.9895L8.55309 12.5624L7.87155 12.1839C7.60775 12.0346 7.29281 11.9749 7.006 11.9749C6.71793 11.9749 6.40207 12.0353 6.13663 12.1832L2.88566 13.9886L2.88487 13.989C2.45484 14.2256 2.17349 14.1851 2.0289 14.0999C1.88461 14.0149 1.7135 13.7894 1.7135 13.2999V5.0107V3.90659Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>

    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}