import { Table, Skeleton, Empty } from 'antd';
import ArkreenPagination from '../components/ArkreenPagination'
import ArkreenMobileTable from '../components/ArkreenMobileTable'
import type { ColumnsType } from 'antd/es/table';
import '../common/css/ArkreenTable.css'


type tableProps = {
    columns:ColumnsType<any>,
    datalist: any,
    pageSize?: number,
    loading:boolean,
    onPageChange?: (page: number, pageSize: number) => void;
    currentPage:number,
    hidePagination?:boolean,
    scroll?:any,
    alwaysHidePagination?:boolean
}

export default function ArkreenTable(props: tableProps): JSX.Element {
  const pageSize = props.pageSize?props.pageSize:25
    function makeItems(n:number) {
        var items = new Array<Object>(n);
        for (var i = 0; i < n; ++i) {
          items[i] = {}
        }
        return items;
    }
    const emptyItems = makeItems(pageSize)
    //
    return (
      <div >
        <div className='arkreen-table-pc'>
          <Table 
            className='table-border' 
            size='small' 
            scroll={props.scroll}
            pagination={false} 
            columns={props.columns} 
            dataSource={props.loading ? [] :props.datalist} 
            locale={{
              emptyText: props.loading ? emptyItems.map(i => 
                <div key={Math.random()} style={{width:'100%',display:'flex',gap:'12px'}}>
                  {props.columns.map(item => <Skeleton.Button key={item.key+"-"+Math.random()} block={true} active={true} style={{marginTop: '3px',marginBottom: '3px', flex:10}}/>) }
                </div>
              ) : <Empty />
            }}
            />
        </div>
        <ArkreenMobileTable loading={props.loading} columns={props.columns}  dataList={props.datalist} emptyItems={emptyItems} />
        {
          props.alwaysHidePagination?<></>:<div>
            {((props.datalist?.length)<pageSize && props.currentPage===1 && !props.hidePagination) ?'':<ArkreenPagination responsive={props.datalist?.length<pageSize}  current={props.currentPage} onChange={props.onPageChange}/>}
          </div>
        }
      </div>
    )
}
