import React from 'react';
import { Layout,ConfigProvider } from 'antd';
import AppSiderBar from './AppSiderBar'
import {ErrorBoundary} from './ErrorBoundary';
import AppTopBar from './AppTopBar';
import AppFooterBar from './AppFooterBar'
import Dashboard from '../routes/Dashboard'
import Accounts from '../routes/Accounts'
import Miners from '../routes/Miners'
import Transactions from '../routes/Transactions'
import Blocks from '../routes/Blocks'
import AccountDetail from '../routes/AccountDetail'
import MinerDetail from '../routes/MinerDetail'
import TransactionDetail from '../routes/TransactionDetail'
import BlockDetail from '../routes/BlockDetail'
import StatGrowth from '../routes/StatGrowth'
import Page404 from '../routes/Page404'
import { Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

const { Content } = Layout;

function ExplorerBody(){
  return (
    <ConfigProvider 
    direction="ltr" 
    theme={{
        token: {
            fontFamily: 'Montserrat',
        },
    }}>
      <ErrorBoundary>
        <RecoilRoot>
          <Layout style={{height:'100vh'}}>
            <AppSiderBar/>
            <Layout>
              <Content style={{overflow:'auto'}}>
              <AppTopBar/>
                <div style={{minHeight: '100%',paddingBottom: '48px'}}>
                  <Routes>
                    <Route path='/' element={<React.Suspense children={<Dashboard />} />} />
                    <Route path='/dashboard' element={<React.Suspense children={<Dashboard />} />} />
                    <Route path='/accounts' element={<React.Suspense children={<Accounts />} />} />
                    <Route path='/account/:address' element={<React.Suspense children={<AccountDetail />} />} />
                    <Route path='/miners' element={<React.Suspense children={<Miners />} />} />
                    <Route path='/miner/:address' element={<React.Suspense children={<MinerDetail />} />} />
                    <Route path='/txs' element={<React.Suspense children={<Transactions />} />} />
                    <Route path='/tx/:hash' element={<React.Suspense children={<TransactionDetail />} />} />
                    <Route path='/blocks' element={<React.Suspense children={<Blocks />} />} />
                    <Route path='/block/:height' element={<React.Suspense children={<BlockDetail />} />} />
                    <Route path='/stat/:type' element={<React.Suspense children={<StatGrowth />} />} />
                    <Route path='/404' element={<React.Suspense children={<Page404 />} />} />
                  </Routes>
                </div>
                <AppFooterBar/>
              </Content>
            </Layout>
          </Layout>
        </RecoilRoot>
      </ErrorBoundary>
    </ConfigProvider>
    
  );
}

export default ExplorerBody;
