import { Skeleton,Empty  } from 'antd';
import '../common/css/ArkreenTable.css'

type tableProps = {
    dataList: any,
    columns: any,
    emptyItems:Object[],
    loading?:boolean
}

export default function ArkreenMobileTable(props: tableProps): JSX.Element {

    return (
      <div className='arkreen-table-m'>
          {
            (props.dataList && props.dataList.length>0 )?props.dataList.map((data:any) => 
            <div key={data.key} className='arkreen-m-table'>
              <table style={{width:'100%'}}>
                <tbody>
                  {
                    props.columns.map((item:any) => <tr style={{height:'32px'}} key={item.title}><td style={{width:'120px',color:'#707079'}}>{item.title}</td><td  style={{color:'#101020'}}>{data[item.dataIndex]}</td></tr>)
                  }
                </tbody>
              </table>
            </div>)
            :
            props.loading?
            props.emptyItems.map(i => 
            <div key={Math.random().toString()} className='arkreen-m-table'>
              <table style={{width:'100%'}}>
                <tbody>
                  {
                    props.columns.map((item:any) => <tr style={{height:'32px'}} key={item.title+"-"+Math.random()}><td style={{width:'120px'}}>{item.title}</td><td><Skeleton.Input style={{height:'32px'}}  active block={true} size='small' /></td></tr>)
                  }
                </tbody>
              </table>
            </div>):<div style={{background:'#FFFFFF',borderRadius:'8px',paddingBlock:'32px'}}><Empty /></div>
            
          }
      </div>
    )
}