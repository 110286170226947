import { useState,useEffect  } from "react";
import ArkreenMinCard from '@/components/ArkreenMinCard';
import ArkreenGrowthTrendCard from '@/components/ArkreenGrowthTrendCard';
import ArkreenMinViewTable from '@/components/ArkreenMinViewTable';
import { Col, Row } from 'antd';
import {MinerLineIcon} from '../icons/MinerLineIcon'
//import {AccountLineIcon} from '../icons/AccountLineIcon'
import {PvCapacityLineIcon} from '../icons/PvCapacityLineIcon'
import {GreenEnergyLineIcon} from '../icons/GreenEnergyLineIcon'
import {ImpactLineIcon} from '../icons/ImpactLineIcon'
import {BlockLineIcon} from '../icons/BlockLineIcon'
import {TransactionLineIcon} from '../icons/TransactionLineIcon'
import {CO2Icon} from '../icons/CO2Icon'
import StatApi from '../api/StatApi'
import BlockApi from '../api/BlockApi'
import TransactionApi from '../api/TransactionApi'
import {formatTxType,formatElectricity,formatPower} from '../utils/DataFormatUtils'
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMain.css'
import moment from 'moment'

//const isShowAccount = process.env.REACT_APP_SHOW_ACCOUNTS

function Dashboard(){
  const [networkInfo,setNetworkInfo] = useState({
    accounts:0,
    capacity: 0,
    miners: 0,
    greenEnergy: 0
  })

  const [txList,setTxList] = useState<any>([])
  const [blockList,setBlockList] = useState<any>([])

  function stateNetworkTrend() {
    StatApi.stateNetworkTrend().then((rsp:any) => {
      if(!rsp.error){
        if(rsp.result){
          const info = {
            accounts: rsp.result.owners?rsp.result.owners:0,
            capacity: rsp.result.power?rsp.result.power:0,
            miners: rsp.result.miners?rsp.result.miners:0,
            greenEnergy: rsp.result.energy?rsp.result.energy:0,
          }
          setNetworkInfo(info)
        }
      }
    })
  }
  //
  const [minerGrowthData, setMinerGrowthData] = useState<any>([]);
  const [pvCapacityGrowthData, setPVCapacityGrowthData] = useState<any>([]);
  const [greenEnergyData, setGreenEnergyData] = useState<any>([]);
  const [CO2, setCO2] = useState<any>([]);
  const timeArr = moment(new Date()).format('LLLL').split(",");
  const [deviceDaily, setDeviceDaily] = useState<any>({
    miner: '0',
    capacity: '0 kW',
    energy: '0 kWh',
    time: timeArr[0]+","+timeArr[1],
    co2:'0 ton ',
  });
  //
  function handleStatDeviceDaily(){
    StatApi.statDeviceDaily().then((rsp:any) => {
      if(!rsp.error && rsp.result.length>0){
        const MinerGrowth:any[] = []
        const Capacity:any[] = []
        const Energy:any[]  = []
        const CO2:any[]  = []
        const deviceDaily = {
          miner: '0',
          capacity: '0 kW',
          energy: '0 kWh',
          time: '',
          co2:'0 ton ',
        }
        let isKwh = false;
        let isKg = false;
        rsp.result.forEach((item:any) => {
          MinerGrowth.push({date:item.dataDate,value:item.minerTotal});
          Capacity.push({date:item.dataDate,value:item.ratedPowerTotal/ 1000 / 1000});
         // Energy.push({date:item.dataDate,value:item.energyGenerationTotal / 1000 / 1000});
          deviceDaily.miner = Number(item.minerTotal).toLocaleString()
          deviceDaily.capacity = Number(Number(item.ratedPowerTotal/1000/1000).toFixed(1)).toLocaleString()+" kW";
          //deviceDaily.energy = Number(Number(item.energyGenerationTotal/1000/1000).toFixed(2)).toLocaleString()+" kWh";
          const energy = item.energyGenerationTotal/1000/1000
          if(energy<10000){
            Energy.push({date:item.dataDate,value:energy});
            deviceDaily.energy = Number(Number(energy).toFixed(2)).toLocaleString()+" kWh";
            isKwh = true
          }else {
            if(isKwh){
              Energy.push({date:item.dataDate,value:energy});
            }else{
              Energy.push({date:item.dataDate,value:energy/1000});
            }
            deviceDaily.energy = Number(Number(energy/1000).toFixed(2)).toLocaleString()+" MWh";
          }
          const timeArr = moment(item.dataDate).format('LLLL').split(",");
          deviceDaily.time = timeArr[0]+","+timeArr[1]
          //const co = Number((item.energyGenerationTotal/1000/1000)*7.09/10000).toFixed(2);
          const co = Number((item.energyGenerationTotal/1000/1000)*7.09/10000);
          if(co<10){
            const temp = Number(co*1000).toFixed(2)
            deviceDaily.co2 = Number(temp).toLocaleString()+" kg "
            CO2.push({date:item.dataDate,value:temp})
            isKg = true
          }else {
            if(isKg){
              CO2.push({date:item.dataDate,value:Number(co*1000).toFixed(2)})
            }else{
              CO2.push({date:item.dataDate,value:co})
            }
            deviceDaily.co2 = Number(Number(co).toFixed(2)).toLocaleString()+" ton "
          }
        });
        setMinerGrowthData(MinerGrowth.slice(-14));
        setPVCapacityGrowthData(Capacity.slice(-14));
        setGreenEnergyData(Energy.slice(-14));
        setDeviceDaily(deviceDaily);
        setCO2(CO2.slice(-14));
      }
    })
  }

  function getTransactionList(){
    TransactionApi.getTransactionList({limit:10}).then((rsp:any) => {
      if(!rsp.error){
        const list = new Array(rsp.result.length); 
          rsp.result.forEach((item:any) => {
            const tx = {
              label: item.hash,
              value: formatTxType(item.type),
              timestamp: item.createTime
            }
            list.push(tx);
          })
          setTxList(list)
        }
      })
  }

  function getBlockList(){
    BlockApi.getBlockList({limit:10}).then((rsp:any) => {
      if(!rsp.error){
        const list:any = []; 
          rsp.result.forEach((item:any) => {
            const tx = {
              label: "#"+(item.height?Number(item.height).toLocaleString():0),
              value: ''+(item.txAmount?item.txAmount:'0')+" Transactions",
              timestamp: item.createTime
            }
            list.push(tx);
          })
          setBlockList(list)
        }
    })
  }
  
  useEffect(() => {
    stateNetworkTrend();
    getTransactionList();
    getBlockList();
    handleStatDeviceDaily();
  },[])
  

  return (
    <div className="arkreen-main">
      <Row gutter={[20,20]}>
        {
            //isShowAccount === 'true'?(<Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}><ArkreenMinCard title='Accounts' icon={<AccountLineIcon />} content={networkInfo.accounts!==undefined?Number(networkInfo.accounts).toLocaleString():""}/></Col>):<></>
        }
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={6} xxl={6}>
          <ArkreenMinCard title='PV Capacity' icon={<PvCapacityLineIcon/>} content={networkInfo.capacity!==undefined?formatPower(Number(networkInfo.capacity),1):''}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={6} xxl={6}>
          <ArkreenMinCard title='Green Energy' icon={<GreenEnergyLineIcon/>} content={networkInfo.greenEnergy!==undefined?formatElectricity(Number(networkInfo.greenEnergy),1):''}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={6} xxl={6}>
          <ArkreenMinCard title='Miners' icon={<MinerLineIcon/>} content={networkInfo.miners!==undefined?Number(networkInfo.miners).toLocaleString():''}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={6} xxl={6}>
          <ArkreenMinCard title='Impact' icon={<ImpactLineIcon/>} content={deviceDaily.co2!==undefined?<span><span>{deviceDaily.co2}</span><span><CO2Icon /></span></span>:''} titleColor="#33A761" valueColor="#00913A" style={{background:"#E6F4EB"}}/>
        </Col>
      </Row>
      <Row gutter={[20,20]} style={{paddingTop:'20px'}}>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <ArkreenGrowthTrendCard fullButton={true} routerLink='/stat/miner' title='Miner Growth' value={deviceDaily.miner} time={deviceDaily.time} data={minerGrowthData}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <ArkreenGrowthTrendCard fullButton={true} routerLink='/stat/capacity' unit="" type={1} title='PV Capacity Growth' value={deviceDaily.capacity} time={deviceDaily.time} data={pvCapacityGrowthData}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <ArkreenGrowthTrendCard fullButton={true} routerLink='/stat/energy' unit="" type={2} title='Green Energy Data Uploaded' value={deviceDaily.energy} time={deviceDaily.time} data={greenEnergyData}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={6}>
          <ArkreenGrowthTrendCard fullButton={true} routerLink='/stat/impact' unit="" type={3} title='Total Impact' value={deviceDaily.co2} time={deviceDaily.time} data={CO2} />
        </Col>
      </Row>
      <Row gutter={[20,20]} style={{paddingTop:'20px'}}>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <ArkreenMinViewTable hrefAll="/txs" href="/tx" title='Transactions' buttonAble={true} buttonName='view all' valueColor="#00913A" dataList={txList} icon={<TransactionLineIcon />}/>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <ArkreenMinViewTable hrefAll="/blocks" href="/block" title='Blocks' buttonAble={true} buttonName='view all' dataList={blockList} icon={<BlockLineIcon/>} />
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
