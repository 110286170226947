import { message } from 'antd';
import { useEffect, useState } from 'react'

export function copyText(text:string){
    const input = document.createElement('input')
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    document.body.removeChild(input);
    message.success('Copied!');
}

interface WindowSize {
    width: number
    height: number
}

export function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = useState<WindowSize>({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  
    useEffect(() => {
      const handler = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
  
      // Set size at the first client-side load
      handler()
  
      window.addEventListener('resize', handler)
  
      // Remove event listener on cleanup
      return () => {
        window.removeEventListener('resize', handler)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    return windowSize
}

export function jumpTo(url:string,target?:string){
  const domA = document.createElement('a')
  domA.href = url
  domA.target = target?target:'_blank'
  domA.click()
}
