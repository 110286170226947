import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const fillStyle = {fill: 'currentColor'}

const Svg = () => (

    <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"} fillRule="evenodd" clipRule="evenodd" d="M12.3436 1.2289C12.4965 1.59893 12.56 2.04165 12.56 2.52V8.97H14.9C15.6941 8.97 16.5507 9.21294 16.896 9.97756C17.2415 10.7425 16.8564 11.5443 16.3322 12.1365L16.3315 12.1373L8.76298 20.7355C8.44695 21.0943 8.10673 21.3847 7.74785 21.5617C7.38578 21.7403 6.9462 21.8284 6.51015 21.6646C6.07369 21.5008 5.80079 21.1448 5.64638 20.7711C5.49348 20.4011 5.43001 19.9584 5.43001 19.48V13.03H3.09001C2.30151 13.03 1.44765 12.79 1.1019 12.0271C0.756905 11.2659 1.13618 10.4645 1.65359 9.8684L1.65702 9.86444L9.22704 1.26446C9.54306 0.905726 9.88328 0.615303 10.2422 0.438301C10.6042 0.259728 11.0438 0.171649 11.4799 0.335354C11.9163 0.499215 12.1892 0.855233 12.3436 1.2289ZM10.353 2.25555C10.5919 1.98443 10.7818 1.84468 10.9057 1.78358C10.9193 1.77687 10.9313 1.77144 10.9418 1.76706C10.9466 1.77691 10.9518 1.7884 10.9573 1.80173C11.0097 1.92857 11.06 2.15836 11.06 2.52V9.72C11.06 10.1342 11.3958 10.47 11.81 10.47H14.9C15.182 10.47 15.3596 10.5144 15.4568 10.5576C15.4932 10.5738 15.5141 10.5876 15.5253 10.5965C15.5245 10.6105 15.521 10.6347 15.5093 10.6717C15.4777 10.7721 15.3941 10.9332 15.2086 11.1427L15.207 11.1445L7.63724 19.7442C7.39826 20.0155 7.20828 20.1553 7.08435 20.2164C7.07075 20.2231 7.05872 20.2286 7.0482 20.2329C7.04341 20.2231 7.03821 20.2116 7.0327 20.1983C6.98029 20.0714 6.93001 19.8416 6.93001 19.48V12.28C6.93001 11.8658 6.59422 11.53 6.18001 11.53H3.09001C2.81013 11.53 2.63423 11.4861 2.53822 11.4435C2.50303 11.4279 2.48266 11.4146 2.47168 11.406C2.47233 11.3917 2.47573 11.3668 2.48755 11.3285C2.51891 11.227 2.60171 11.0648 2.78484 10.8534L10.353 2.25555Z"/>
    </svg>

);

let prop:any = undefined

export function GreenEnergyLineIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={Svg} {...props} />
    );
}