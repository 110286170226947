import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function MenuIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const Svg = () => (
        <svg width={props.style?.width?props.style.width:"1em"} height={props.style?.height?props.style.height:"1em"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 4.75C20.4142 4.75 20.75 5.08579 20.75 5.5C20.75 5.91421 20.4142 6.25 20 6.25H4C3.58579 6.25 3.25 5.91421 3.25 5.5C3.25 5.08579 3.58579 4.75 4 4.75H20Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path d="M20.75 18.5C20.75 18.0858 20.4142 17.75 20 17.75H4C3.58579 17.75 3.25 18.0858 3.25 18.5C3.25 18.9142 3.58579 19.25 4 19.25H20C20.4142 19.25 20.75 18.9142 20.75 18.5Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path d="M20 11.25C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75H4C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25H20Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>
    );
    //
    return (
        <Icon component={Svg} {...props} />
    );
}