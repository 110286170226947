import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const fillStyle = {fill: 'currentColor'}

const Svg = () => (

  <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.66659 7.60004V10.4C9.66659 12.7334 8.73325 13.6667 6.39992 13.6667H3.59992C1.26659 13.6667 0.333252 12.7334 0.333252 10.4V7.60004C0.333252 5.26671 1.26659 4.33337 3.59992 4.33337H6.39992C8.73325 4.33337 9.66659 5.26671 9.66659 7.60004Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#40404D"}/>
    <path d="M10.3999 0.333374H7.59992C5.54453 0.333374 4.58057 1.06277 4.37975 2.82605C4.33773 3.19494 4.64325 3.50004 5.01452 3.50004H6.39992C9.19992 3.50004 10.4999 4.80004 10.4999 7.60004V8.98544C10.4999 9.35671 10.805 9.66223 11.1739 9.62021C12.9372 9.41939 13.6666 8.45543 13.6666 6.40004V3.60004C13.6666 1.26671 12.7333 0.333374 10.3999 0.333374Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#40404D"}/>
  </svg>


);

let prop:any = undefined

export function CopyIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={Svg} {...props} />
    );
}

