import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function CloseMenuIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"24px"} height={props.style?.height?props.style.height:"24px"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.16639 5.10541C5.87349 4.81252 5.39862 4.81252 5.10573 5.10541C4.81283 5.39831 4.81283 5.87318 5.10573 6.16607L10.9394 11.9997L5.10572 17.8333C4.81282 18.1262 4.81282 18.6011 5.10572 18.894C5.39861 19.1869 5.87348 19.1869 6.16638 18.894L12 13.0604L17.8336 18.894C18.1265 19.1869 18.6014 19.1869 18.8943 18.894C19.1872 18.6011 19.1872 18.1262 18.8943 17.8333L13.0607 11.9997L18.8943 6.16608C19.1872 5.87319 19.1872 5.39831 18.8943 5.10542C18.6014 4.81253 18.1265 4.81253 17.8336 5.10542L12 10.939L6.16639 5.10541Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}