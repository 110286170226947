import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const fillStyle = {fill: 'currentColor'}

const Svg = () => (

  <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9791 1.25428C9.75438 0.547197 8.24547 0.547196 7.02076 1.25428L2.41553 3.91312C1.19082 4.62021 0.436361 5.92696 0.436361 7.34113V12.6588C0.436361 14.073 1.19081 15.3797 2.41553 16.0868L7.02076 18.7456C8.24547 19.4527 9.75438 19.4527 10.9791 18.7456L15.5843 16.0868C16.809 15.3797 17.5635 14.073 17.5635 12.6588V7.34113C17.5635 5.92696 16.809 4.62021 15.5843 3.91312L10.9791 1.25428ZM7.64576 2.33682C8.48372 1.85302 9.51613 1.85302 10.3541 2.33682L14.9593 4.99565C15.3066 5.19617 15.5987 5.46717 15.8216 5.78468L8.99989 9.73613L2.17641 5.78732C2.39956 5.46867 2.69226 5.19672 3.04053 4.99565L7.64576 2.33682ZM1.7128 6.96325C1.69532 7.08742 1.68636 7.21364 1.68636 7.34113V12.6588C1.68636 13.6264 2.20257 14.5205 3.04053 15.0043L7.64576 17.6631C7.87678 17.7965 8.12257 17.8931 8.375 17.9529V10.8187L1.7128 6.96325ZM9.625 17.9529C9.87738 17.8931 10.1231 17.7965 10.3541 17.6631L14.9593 15.0043C15.7973 14.5205 16.3135 13.6264 16.3135 12.6588V7.34113C16.3135 7.21249 16.3044 7.08515 16.2866 6.9599L9.625 10.8186V17.9529Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
  </svg>
  
);

let prop:any = undefined

export function BlockLineIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={Svg} {...props} />
    );
}

