import React,{ useState,useEffect }  from 'react';
import { Layout,Row,Col, } from 'antd';
import {MinerLineIcon} from '../icons/MinerLineIcon'
import {ArkreenLogoSingleIcon} from '../icons/ArkreenLogoSingleIcon'
import {ArkreenLogoTextUnionIcon} from '../icons/ArkreenLogoTextUnionIcon'
import {AccountLineIcon} from '../icons/AccountLineIcon'
import {TransactionLineIcon} from '../icons/TransactionLineIcon'
import {BlockLineIcon} from '../icons/BlockLineIcon'
import {ArkreenHomeIcon} from '../icons/ArkreenHomeIcon'
import {ArkreenConsoleIcon} from '../icons/ArkreenConsoleIcon'
import {DashboardLineIcon} from '../icons/DashboardLineIcon'
import {ArkreenDocsIcon} from '../icons/ArkreenDocsIcon'
import {ArkreenMenuSwitchLeftIcon} from '../icons/ArkreenMenuSwitchLeftIcon'
import {ArkreenMenuSwitchRightIcon} from '../icons/ArkreenMenuSwitchRightIcon'
import ArkreenMenu from '../components/ArkreenMenu'
import { useLocation,useNavigate } from 'react-router-dom'
import {useWindowSize,jumpTo} from '../utils/BrowserUtils'
import '../common/css/ArkreenMenu.css'
const { Sider } = Layout;
const consoleUrl = process.env.REACT_APP_CONSOLE_URL

function AppSiderBar(){

  const menuList = [];
  const dashboard = {
    key: '',
    sort: '1',
    icon: <DashboardLineIcon/>,
    label: 'Dashboard',
    url: '/'
  }
  const accounts = {
    key: 'accounts',
    sort: '2',
    icon: <AccountLineIcon/>,
    label: 'Accounts',
    url: '/accounts'
  }
  const miners = {
    key: 'miners',
    sort: '3',
    icon: <MinerLineIcon/>,
    label: 'Miners',
    url: '/miners'
  }
  const transactions = {
    key: 'txs',
    sort: '4',
    icon: <TransactionLineIcon />,
    label: 'Transactions',
    url: '/txs'
  }
  const blocks = {
    key: 'blocks',
    sort: '5',
    icon: <BlockLineIcon/>,
    label: 'Blocks',
    url: '/blocks',
  }

  menuList.push(dashboard);
  menuList.push(accounts)
  menuList.push(miners)
  menuList.push(transactions)
  menuList.push(blocks)

  const location = useLocation();
  const [getSelectKey] = useState(location.pathname.substring(1))
  const [collapsed,setCollapsed] = useState(false)
  const [userControl,setUserControl] = useState(false)

  const navigate  = useNavigate()
  function gotoHome(){
    navigate('/')
  }
  function siderChange(){
    setUserControl(true);
    setCollapsed(!collapsed)
  }

  const windowSize = useWindowSize();

  useEffect(() => {
    if(!userControl){
      (windowSize.width)>992?setCollapsed(false):setCollapsed(true)
    }
  },[windowSize.width])//eslint-disable-line

  return (
    <Row>
      <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Sider breakpoint='lg' width="240px" className='menu-box' collapsed={collapsed}>
          <div onClick = {() => gotoHome()} >
            <div className={collapsed?'hide':'logo-long'}>
              <ArkreenLogoSingleIcon /><ArkreenLogoTextUnionIcon style={{marginLeft:'10px'}}/>
            </div>
            <div className={collapsed?'logo-short':'hide'}><ArkreenLogoSingleIcon /></div>
          </div>
          <ArkreenMenu selectKey={getSelectKey} menuList={menuList} collapsed={collapsed}/>
          <div className={collapsed?'footer-link-main-collapsed':'footer-link-main'}>
            <ul style={{listStyle: 'none',padding:'0px'}}>
              <li className={collapsed?'footer-link-item-center':'footer-link-item-left'} onClick={() => jumpTo('https://arkreen.com/')}>
                <span className={collapsed?'footer-link-logo-collapsed':'footer-link-logo'}><ArkreenHomeIcon/></span>
                <span className={collapsed?'hide':'footer-link-span'}>Home</span>
              </li>
              <li className={collapsed?'footer-link-item-center':'footer-link-item-left'} onClick={() => jumpTo(""+consoleUrl)}>
                <span className={collapsed?'footer-link-logo-collapsed':'footer-link-logo'}><ArkreenConsoleIcon/></span>
                <span className={collapsed?'hide':'footer-link-span'}>Console</span>
              </li>
              <li className={collapsed?'footer-link-item-center':'footer-link-item-left'} onClick={() => jumpTo('https://docs.arkreen.com/')}>
                <span className={collapsed?'footer-link-logo-collapsed':'footer-link-logo'}><ArkreenDocsIcon/></span>
                <span className={collapsed?'hide':'footer-link-span'}>Docs</span>
              </li>
            </ul>
            <div className={collapsed?'hide':'menu-switch-btn-left'}>
              <div onClick={() => siderChange()}><ArkreenMenuSwitchLeftIcon/></div>
            </div>
            <div className={collapsed?'menu-switch-btn-right':'hide'}>
              <div onClick={() => siderChange()}><ArkreenMenuSwitchRightIcon/></div>
            </div>
          </div>
        </Sider>
      </Col>
    </Row>
  );
}

export default AppSiderBar;
