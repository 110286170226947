import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const fillStyle = {fill: 'currentColor'}

const Svg = () => (

  <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path style={fillStyle} d="M7.00008 6.02223C7.00008 5.88548 7.0092 5.74873 7.01831 5.60286V5.56639C7.03655 5.39317 7.05478 5.22907 7.08213 5.05585C7.08213 5.03762 7.09125 5.01027 7.09125 4.98292C7.1186 4.82793 7.15507 4.68206 7.19153 4.52708L7.21888 4.41767C7.25535 4.28092 7.30094 4.15329 7.35564 4.02565C7.37387 3.98007 7.3921 3.92537 7.41034 3.87978C7.45592 3.76126 7.51062 3.65186 7.56532 3.53334C7.59267 3.47864 7.62002 3.42394 7.64737 3.36012C7.70208 3.25072 7.76589 3.15044 7.82971 3.05015C7.86618 2.99545 7.89353 2.93163 7.93 2.87693C8.00293 2.76753 8.07586 2.66725 8.15792 2.56696C8.18527 2.53049 8.2035 2.50314 8.23085 2.46668L8.2035 2.43933C7.95735 2.19317 7.95735 1.79203 8.2035 1.54588C8.44965 1.29972 8.85079 1.29972 9.09695 1.54588L9.1243 1.57323C10.0177 0.862118 11.1026 0.433628 12.2422 0.351577C12.4428 0.333343 12.6525 0.333344 12.8531 0.333344L12.844 0.634198L12.9625 1.56411C12.0417 1.8285 11.1938 2.28434 10.4554 2.89517L13.4821 5.92195C13.7283 6.1681 13.7283 6.56924 13.4821 6.81539C13.236 7.06155 12.8348 7.06155 12.5887 6.81539L9.5619 3.78861C8.95108 4.52708 8.49524 5.37494 8.23085 6.29574L7.00008 6.13163V5.99488V6.02223Z" fill={prop.style?.color?prop.style?.color:"#101020"}/>
    <path style={fillStyle} d="M12.4639 13.6667H1.25836C0.803326 13.6667 0.482013 13.2209 0.625909 12.7892L2.75002 6.41687C3.11024 5.33619 4.57217 5.17571 5.15825 6.15252L7.28644 9.69949C7.48983 10.0385 7.94184 10.127 8.2581 9.88983L9.75276 8.76883C10.0652 8.53454 10.5111 8.61769 10.7181 8.94883L13.0292 12.6467C13.3068 13.0907 12.9875 13.6667 12.4639 13.6667Z" fill={prop.style?.color?prop.style?.color:"#101020"}/>
    <path style={fillStyle} d="M3.00008 4.00001C3.00008 4.5523 2.55237 5.00001 2.00008 5.00001C1.4478 5.00001 1.00008 4.5523 1.00008 4.00001C1.00008 3.44773 1.4478 3.00001 2.00008 3.00001C2.55237 3.00001 3.00008 3.44773 3.00008 4.00001Z" fill={prop.style?.color?prop.style?.color:"#101020"}/>
    <path style={fillStyle} d="M6.33341 1.66668C6.33341 2.03487 6.03494 2.33334 5.66675 2.33334C5.29856 2.33334 5.00008 2.03487 5.00008 1.66668C5.00008 1.29849 5.29856 1.00001 5.66675 1.00001C6.03494 1.00001 6.33341 1.29849 6.33341 1.66668Z" fill={prop.style?.color?prop.style?.color:"#101020"}/>
    <path style={fillStyle} d="M13.0001 9.66668C13.0001 10.0349 12.7016 10.3333 12.3334 10.3333C11.9652 10.3333 11.6667 10.0349 11.6667 9.66668C11.6667 9.29849 11.9652 9.00001 12.3334 9.00001C12.7016 9.00001 13.0001 9.29849 13.0001 9.66668Z" fill={prop.style?.color?prop.style?.color:"#101020"}/>
  </svg>

);

let prop:any = undefined

export function MinerIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={Svg} {...props} />
    );
}

