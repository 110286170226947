import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function CapacityIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3547 5.98076C11.4021 6.02845 11.4379 6.08332 11.4621 6.14186C11.4865 6.20081 11.5 6.26546 11.5 6.33325V7.66659C11.5 7.94273 11.2762 8.16659 11 8.16659C10.7239 8.16659 10.5 7.94273 10.5 7.66659V7.54036L8.55359 9.4868C8.44767 9.59273 8.29984 9.64558 8.15077 9.63082C8.0017 9.61606 7.86711 9.53524 7.78402 9.4106L7.05566 8.31807L5.35359 10.0201C5.15833 10.2154 4.84175 10.2154 4.64649 10.0201C4.45123 9.82488 4.45123 9.5083 4.64649 9.31303L6.77982 7.1797C6.88574 7.07378 7.03358 7.02093 7.18264 7.03569C7.33171 7.05045 7.46631 7.13126 7.5494 7.2559L8.27775 8.34843L9.79293 6.83325H9.66671C9.39057 6.83325 9.16671 6.60939 9.16671 6.33325C9.16671 6.05711 9.39057 5.83325 9.66671 5.83325H11C11.0678 5.83325 11.1325 5.84675 11.1914 5.87119C11.25 5.89541 11.3048 5.93123 11.3525 5.97864L11.3536 5.9797L11.3547 5.98076Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.00004 0.833252C4.28406 0.833252 2.9528 1.17339 2.06315 2.06303C1.17351 2.95268 0.833374 4.28394 0.833374 5.99992V9.99992C0.833374 11.7159 1.17351 13.0472 2.06315 13.9368C2.9528 14.8265 4.28406 15.1666 6.00004 15.1666H10C11.716 15.1666 13.0473 14.8265 13.9369 13.9368C14.8266 13.0472 15.1667 11.7159 15.1667 9.99992V5.99992C15.1667 4.28394 14.8266 2.95268 13.9369 2.06303C13.0473 1.17339 11.716 0.833252 10 0.833252H6.00004ZM1.83337 5.99992C1.83337 4.38257 2.15991 3.38049 2.77026 2.77014C3.38061 2.15978 4.38269 1.83325 6.00004 1.83325H10C11.6174 1.83325 12.6195 2.15978 13.2298 2.77014C13.8402 3.38049 14.1667 4.38257 14.1667 5.99992V9.99992C14.1667 11.6173 13.8402 12.6193 13.2298 13.2297C12.6195 13.8401 11.6174 14.1666 10 14.1666H6.00004C4.38269 14.1666 3.38061 13.8401 2.77026 13.2297C2.15991 12.6193 1.83337 11.6173 1.83337 9.99992V5.99992Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}