import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const fillStyle = {fill: 'currentColor'}

const Svg = () => (

  <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM8.38388 6.61612C7.89573 6.12796 7.10427 6.12796 6.61612 6.61612C6.12796 7.10427 6.12796 7.89573 6.61612 8.38388L8.23223 10L6.61612 11.6161C6.12796 12.1043 6.12796 12.8957 6.61612 13.3839C7.10427 13.872 7.89573 13.872 8.38388 13.3839L10 11.7678L11.6161 13.3839C12.1043 13.872 12.8957 13.872 13.3839 13.3839C13.872 12.8957 13.872 12.1043 13.3839 11.6161L11.7678 10L13.3839 8.38388C13.872 7.89573 13.872 7.10427 13.3839 6.61612C12.8957 6.12796 12.1043 6.12796 11.6161 6.61612L10 8.23223L8.38388 6.61612Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
  </svg>

);

let prop:any = undefined

export function ErrorIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={Svg} {...props} />
    );
}

