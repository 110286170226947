import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function ValidatorIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"20px"} height={props.style?.height?props.style.height:"20px"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM6.53 11.47C6.82 11.76 6.82 12.24 6.53 12.53C6.38 12.68 6.19 12.75 6 12.75C5.81 12.75 5.62 12.68 5.47 12.53L3.47 10.53C3.18 10.24 3.18 9.76 3.47 9.47L5.47 7.47C5.76 7.18 6.24 7.18 6.53 7.47C6.82 7.76 6.82 8.24 6.53 8.53L5.06 10L6.53 11.47ZM11.69 7.96L9.69 12.63C9.57 12.91 9.29 13.08 9 13.08C8.9 13.08 8.8 13.06 8.71 13.02C8.33 12.86 8.15 12.42 8.32 12.03L10.32 7.36C10.48 6.98 10.92 6.8 11.3 6.97C11.68 7.14 11.85 7.58 11.69 7.96ZM16.53 10.53L14.53 12.53C14.38 12.68 14.19 12.75 14 12.75C13.81 12.75 13.62 12.68 13.47 12.53C13.18 12.24 13.18 11.76 13.47 11.47L14.94 10L13.47 8.53C13.18 8.24 13.18 7.76 13.47 7.47C13.76 7.18 14.24 7.18 14.53 7.47L16.53 9.47C16.82 9.76 16.82 10.24 16.53 10.53Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}