
import { Skeleton,QRCode, Popover,Divider } from 'antd';
import {copyText} from '../utils/BrowserUtils'
import {CopyIcon} from '../icons/CopyIcon'
import {QrCodeIcon} from '../icons/QrCodeIcon'
import '../common/css/ArkreenInfoHeaderCard.css'
type cardProps = {
    title: any,
    value: string|undefined,
    tag?:string
}

export default function ArkreenInfoHeaderCard(props: cardProps): JSX.Element {
    return (
        <div>
            <div className='detail-info-box-header'>{props.title?props.title:<Skeleton.Input style={{width:'120px'}} active block={true} size='small' />}<span className='detail-header-block-height'>{props.tag?props.tag:''}</span></div>
            <div className='detail-info-box'>
                <div className='long-string'>
                    <span style={{marginInlineEnd:'5px'}}>{props.value?props.value:<Skeleton.Input style={{width:'250px'}} active block={true} size='small' />}</span>
                </div>
                {
                    props.value?(
                        <div className='copy-qrcode-box'>
                            <span onClick={() => copyText(props.value?props.value:'') } className='copy-btn'><CopyIcon /></span>
                            <span className='copy-btn'><Popover overlayInnerStyle={{ padding: 0 }} content={<QRCode value={props.value?props.value:''} bordered={false} />}><QrCodeIcon /></Popover></span>
                        </div>
                    ):<Skeleton.Button style={{marginLeft:'12px'}} active size='small' />
                }
            </div>
            <div className='divider'>
                <Divider dashed={true} style={{margin:'0px 0px'}} />
            </div>
        </div>
    )
}
    