import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function NetworkIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"20px"} height={props.style?.height?props.style.height:"20px"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.65 18.91C5.62 18.91 5.58 18.93 5.55 18.93C3.61 17.97 2.03 16.38 1.06 14.44C1.06 14.41 1.08 14.37 1.08 14.34C2.3 14.7 3.56 14.97 4.81 15.18C5.03 16.44 5.29 17.69 5.65 18.91Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M18.94 14.45C17.95 16.44 16.3 18.05 14.29 19.02C14.67 17.75 14.99 16.47 15.2 15.18C16.46 14.97 17.7 14.7 18.92 14.34C18.91 14.38 18.94 14.42 18.94 14.45Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M19.02 5.71C17.76 5.33 16.49 5.02 15.2 4.8C14.99 3.51 14.68 2.23 14.29 0.98C16.36 1.97 18.03 3.64 19.02 5.71Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M5.65 1.09C5.29 2.31 5.03 3.55 4.82 4.81C3.53 5.01 2.25 5.33 0.98 5.71C1.95 3.7 3.56 2.05 5.55 1.06C5.58 1.06 5.62 1.09 5.65 1.09Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M13.49 4.59C11.17 4.33 8.83 4.33 6.51 4.59C6.76 3.22 7.08 1.85 7.53 0.53C7.55 0.45 7.54 0.39 7.55 0.31C8.34 0.12 9.15 0 10 0C10.84 0 11.66 0.12 12.44 0.31C12.45 0.39 12.45 0.45 12.47 0.53C12.92 1.86 13.24 3.22 13.49 4.59Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M4.59 13.49C3.21 13.24 1.85 12.92 0.53 12.47C0.45 12.45 0.39 12.46 0.31 12.45C0.12 11.66 0 10.85 0 10C0 9.16 0.12 8.34 0.31 7.56C0.39 7.55 0.45 7.55 0.53 7.53C1.86 7.09 3.21 6.76 4.59 6.51C4.34 8.83 4.34 11.17 4.59 13.49Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M20 10C20 10.85 19.88 11.66 19.69 12.45C19.61 12.46 19.55 12.45 19.47 12.47C18.14 12.91 16.78 13.24 15.41 13.49C15.67 11.17 15.67 8.83 15.41 6.51C16.78 6.76 18.15 7.08 19.47 7.53C19.55 7.55 19.61 7.56 19.69 7.56C19.88 8.35 20 9.16 20 10Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M13.49 15.41C13.24 16.79 12.92 18.15 12.47 19.47C12.45 19.55 12.45 19.61 12.44 19.69C11.66 19.88 10.84 20 10 20C9.15 20 8.34 19.88 7.55 19.69C7.54 19.61 7.55 19.55 7.53 19.47C7.09 18.14 6.76 16.79 6.51 15.41C7.67 15.54 8.83 15.63 10 15.63C11.17 15.63 12.34 15.54 13.49 15.41Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
            <path d="M13.7633 13.7633C11.2622 14.0789 8.73776 14.0789 6.23667 13.7633C5.92111 11.2622 5.92111 8.73776 6.23667 6.23667C8.73776 5.92111 11.2622 5.92111 13.7633 6.23667C14.0789 8.73776 14.0789 11.2622 13.7633 13.7633Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
        </svg>

    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}