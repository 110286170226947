import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function TelegramIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14px"} height={props.style?.height?props.style.height:"14px"} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.6177 6.8479C13.6177 10.4692 10.682 13.4048 7.06079 13.4048C3.43953 13.4048 0.503906 10.4692 0.503906 6.8479C0.503906 3.22664 3.43953 0.291016 7.06079 0.291016C10.682 0.291016 13.6177 3.22664 13.6177 6.8479ZM7.68912 4.83599C6.56268 5.32255 3.31473 6.70433 3.31473 6.70433C2.54499 7.01573 2.99555 7.30765 2.99555 7.30765C2.99555 7.30765 3.65265 7.54121 4.21589 7.71634C4.77911 7.8915 5.07951 7.6969 5.07951 7.6969C5.07951 7.6969 6.39371 6.80165 7.72667 5.848C8.66537 5.18631 8.4401 5.73124 8.21479 5.96479C7.72667 6.4708 6.91938 7.26873 6.2435 7.91098C5.94313 8.18346 6.09331 8.41699 6.22474 8.53375C6.60696 8.86902 7.50718 9.47876 7.91589 9.75558C8.02913 9.83228 8.10464 9.88342 8.12094 9.89609C8.21479 9.97396 8.74049 10.3243 9.05965 10.2464C9.37881 10.1686 9.41635 9.72093 9.41635 9.72093C9.41635 9.72093 9.66041 8.12506 9.88572 6.66541C9.92745 6.3788 9.96918 6.09821 10.0081 5.83657C10.1092 5.15657 10.1913 4.60459 10.2049 4.40782C10.2612 3.74613 9.58532 4.01858 9.58532 4.01858C9.58532 4.01858 8.12094 4.64138 7.68912 4.83599Z"  style={fillStyle} fill={props.style?.color?props.style.color:"#1D1D20"}/>
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}