import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function EnergyIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.22913 1.48587C8.33106 1.73256 8.37338 2.0277 8.37338 2.3466V6.6466H9.93338C10.4628 6.6466 11.0339 6.80856 11.2641 7.31831C11.4944 7.82828 11.2376 8.3628 10.8882 8.75757L10.8877 8.75812L5.84203 14.4903C5.63134 14.7295 5.40453 14.9231 5.16527 15.0411C4.9239 15.1601 4.63084 15.2188 4.34014 15.1097C4.04917 15.0005 3.86723 14.7631 3.7643 14.514C3.66236 14.2673 3.62005 13.9722 3.62005 13.6533V9.35327H2.06005C1.53438 9.35327 0.965139 9.19324 0.734639 8.68468C0.504644 8.17723 0.757495 7.64296 1.10244 7.24553L1.10472 7.2429L6.1514 1.50957C6.36208 1.27042 6.5889 1.0768 6.82815 0.958801C7.06953 0.839753 7.36258 0.781033 7.65328 0.89017C7.94426 0.99941 8.1262 1.23676 8.22913 1.48587ZM6.90203 2.1703C7.06128 1.98955 7.18789 1.89639 7.27048 1.85565C7.27956 1.85118 7.28757 1.84756 7.29459 1.84464C7.29778 1.85121 7.30125 1.85886 7.30492 1.86776C7.33986 1.95232 7.37338 2.1055 7.37338 2.3466V7.1466C7.37338 7.42274 7.59724 7.6466 7.87338 7.6466H9.93338C10.1214 7.6466 10.2398 7.67621 10.3046 7.70503C10.3289 7.71582 10.3428 7.725 10.3502 7.73091C10.3497 7.74025 10.3474 7.7564 10.3396 7.7811C10.3185 7.848 10.2628 7.95542 10.1391 8.09508L10.1381 8.09624L5.09153 13.8294C4.93222 14.0103 4.80556 14.1035 4.72294 14.1442C4.71387 14.1487 4.70586 14.1523 4.69884 14.1552C4.69565 14.1487 4.69218 14.141 4.68851 14.1321C4.65356 14.0476 4.62005 13.8944 4.62005 13.6533V8.85327C4.62005 8.57713 4.39619 8.35327 4.12005 8.35327H2.06005C1.87346 8.35327 1.7562 8.324 1.69219 8.2956C1.66873 8.2852 1.65515 8.27635 1.64783 8.2706C1.64826 8.26107 1.65052 8.24446 1.65841 8.21894C1.67932 8.15124 1.73452 8.04314 1.8566 7.90222L6.90203 2.1703Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>

    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}