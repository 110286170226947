import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function DangerIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"20px"} height={props.style?.height?props.style.height:"20px"} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.99992 13.6666C10.6818 13.6666 13.6666 10.6818 13.6666 6.99992C13.6666 3.31802 10.6818 0.333252 6.99992 0.333252C3.31802 0.333252 0.333252 3.31802 0.333252 6.99992C0.333252 10.6818 3.31802 13.6666 6.99992 13.6666ZM5.92251 4.744C5.59707 4.41856 5.06943 4.41856 4.744 4.744C4.41856 5.06943 4.41856 5.59707 4.744 5.92251L5.82141 6.99992L4.744 8.07733C4.41856 8.40277 4.41856 8.9304 4.744 9.25584C5.06943 9.58128 5.59707 9.58128 5.92251 9.25584L6.99992 8.17843L8.07733 9.25584C8.40277 9.58128 8.9304 9.58128 9.25584 9.25584C9.58128 8.9304 9.58128 8.40277 9.25584 8.07733L8.17843 6.99992L9.25584 5.92251C9.58128 5.59707 9.58128 5.06943 9.25584 4.744C8.9304 4.41856 8.40277 4.41856 8.07733 4.744L6.99992 5.82141L5.92251 4.744Z" style={fillStyle} fill={props.style?.color?props.style.color:"#101020"}/>
        </svg>
    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}