import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function OnboardingTimeIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"14.33px"} height={props.style?.height?props.style.height:"14.33px"} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.99996 0.833252C4.72382 0.833252 4.49996 1.05711 4.49996 1.33325C4.49996 1.60939 4.72382 1.83325 4.99996 1.83325H8.99996C9.2761 1.83325 9.49996 1.60939 9.49996 1.33325C9.49996 1.05711 9.2761 0.833252 8.99996 0.833252H4.99996Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path d="M6.99996 4.83325C7.2761 4.83325 7.49996 5.05711 7.49996 5.33325V8.66659C7.49996 8.94273 7.2761 9.16659 6.99996 9.16659C6.72382 9.16659 6.49996 8.94273 6.49996 8.66659V5.33325C6.49996 5.05711 6.72382 4.83325 6.99996 4.83325Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0.666626 8.83325C0.666626 5.33711 3.50382 2.49992 6.99996 2.49992C10.4961 2.49992 13.3333 5.33711 13.3333 8.83325C13.3333 12.3294 10.4961 15.1666 6.99996 15.1666C3.50382 15.1666 0.666626 12.3294 0.666626 8.83325ZM6.99996 3.49992C4.0561 3.49992 1.66663 5.88939 1.66663 8.83325C1.66663 11.7771 4.0561 14.1666 6.99996 14.1666C9.94382 14.1666 12.3333 11.7771 12.3333 8.83325C12.3333 5.88939 9.94382 3.49992 6.99996 3.49992Z" style={fillStyle} fill={props.style?.color?props.style.color:"#40404D"}/>
        </svg>

    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}