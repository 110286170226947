import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function CO2Icon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const svg = () => (

        <svg width="38" height="20" viewBox="0 0 38 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="38" height="20" rx="6" fill="#CCE9D8"/>
            <path d="M10.064 15.584C9.2 15.584 8.39467 15.4453 7.648 15.168C6.912 14.88 6.272 14.4747 5.728 13.952C5.184 13.4293 4.75733 12.816 4.448 12.112C4.14933 11.408 4 10.6347 4 9.792C4 8.94933 4.14933 8.176 4.448 7.472C4.75733 6.768 5.184 6.15467 5.728 5.632C6.28267 5.10933 6.928 4.70933 7.664 4.432C8.4 4.144 9.20533 4 10.08 4C11.0507 4 11.9253 4.17067 12.704 4.512C13.4933 4.84267 14.1547 5.33333 14.688 5.984L13.024 7.52C12.64 7.08267 12.2133 6.75733 11.744 6.544C11.2747 6.32 10.7627 6.208 10.208 6.208C9.68533 6.208 9.20533 6.29333 8.768 6.464C8.33067 6.63467 7.952 6.88 7.632 7.2C7.312 7.52 7.06133 7.89867 6.88 8.336C6.70933 8.77333 6.624 9.25867 6.624 9.792C6.624 10.3253 6.70933 10.8107 6.88 11.248C7.06133 11.6853 7.312 12.064 7.632 12.384C7.952 12.704 8.33067 12.9493 8.768 13.12C9.20533 13.2907 9.68533 13.376 10.208 13.376C10.7627 13.376 11.2747 13.2693 11.744 13.056C12.2133 12.832 12.64 12.496 13.024 12.048L14.688 13.584C14.1547 14.2347 13.4933 14.7307 12.704 15.072C11.9253 15.4133 11.0453 15.584 10.064 15.584Z" fill="#00913A"/>
            <path d="M21.644 15.584C20.7587 15.584 19.9373 15.44 19.18 15.152C18.4333 14.864 17.7827 14.4587 17.228 13.936C16.684 13.4133 16.2573 12.8 15.948 12.096C15.6493 11.392 15.5 10.624 15.5 9.792C15.5 8.96 15.6493 8.192 15.948 7.488C16.2573 6.784 16.6893 6.17067 17.244 5.648C17.7987 5.12533 18.4493 4.72 19.196 4.432C19.9427 4.144 20.7533 4 21.628 4C22.5133 4 23.324 4.144 24.06 4.432C24.8067 4.72 25.452 5.12533 25.996 5.648C26.5507 6.17067 26.9827 6.784 27.292 7.488C27.6013 8.18133 27.756 8.94933 27.756 9.792C27.756 10.624 27.6013 11.3973 27.292 12.112C26.9827 12.816 26.5507 13.4293 25.996 13.952C25.452 14.464 24.8067 14.864 24.06 15.152C23.324 15.44 22.5187 15.584 21.644 15.584ZM21.628 13.376C22.1293 13.376 22.588 13.2907 23.004 13.12C23.4307 12.9493 23.804 12.704 24.124 12.384C24.444 12.064 24.6893 11.6853 24.86 11.248C25.0413 10.8107 25.132 10.3253 25.132 9.792C25.132 9.25867 25.0413 8.77333 24.86 8.336C24.6893 7.89867 24.444 7.52 24.124 7.2C23.8147 6.88 23.4467 6.63467 23.02 6.464C22.5933 6.29333 22.1293 6.208 21.628 6.208C21.1267 6.208 20.6627 6.29333 20.236 6.464C19.82 6.63467 19.452 6.88 19.132 7.2C18.812 7.52 18.5613 7.89867 18.38 8.336C18.2093 8.77333 18.124 9.25867 18.124 9.792C18.124 10.3147 18.2093 10.8 18.38 11.248C18.5613 11.6853 18.8067 12.064 19.116 12.384C19.436 12.704 19.8093 12.9493 20.236 13.12C20.6627 13.2907 21.1267 13.376 21.628 13.376Z" fill="#00913A"/>
            <path d="M29.0259 15.342V14.472L31.8059 11.832C32.0392 11.612 32.2125 11.4187 32.3259 11.252C32.4392 11.0853 32.5125 10.932 32.5459 10.792C32.5859 10.6453 32.6059 10.5087 32.6059 10.382C32.6059 10.062 32.4959 9.81535 32.2759 9.64201C32.0559 9.46201 31.7325 9.37201 31.3059 9.37201C30.9659 9.37201 30.6559 9.43201 30.3759 9.55201C30.1025 9.67201 29.8659 9.85535 29.6659 10.102L28.7559 9.40201C29.0292 9.03535 29.3959 8.75201 29.8559 8.55201C30.3225 8.34535 30.8425 8.24201 31.4159 8.24201C31.9225 8.24201 32.3625 8.32535 32.7359 8.49201C33.1159 8.65201 33.4059 8.88201 33.6059 9.18201C33.8125 9.48201 33.9159 9.83868 33.9159 10.252C33.9159 10.4787 33.8859 10.7053 33.8259 10.932C33.7659 11.152 33.6525 11.3853 33.4859 11.632C33.3192 11.8787 33.0759 12.1553 32.7559 12.462L30.3659 14.732L30.0959 14.242H34.1859V15.342H29.0259Z" fill="#00913A"/>
        </svg>

    );
    //
    return (
        <Icon component={svg} {...props} />
    );
}