import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function EllipseIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const fillStyle = {fill: 'currentColor'}
    const svg = () => (
        <svg width={props.style?.width?props.style.width:"6px"} height={props.style?.height?props.style.height:"6px"} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3" cy="3" r="3" style={fillStyle} fill={props.style?.color?props.style.color:"#19BEBE"}/>
        </svg>
    );
    //
    return (
        <span style={{width:'12.67px',height:'12.67px',lineHeight:'12.67px',display:'inline-block',marginTop:'-6px',verticalAlign:'middle'}}><Icon component={svg} {...props} /></span>
    );
}