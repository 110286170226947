import { useState,useEffect  } from "react";
import { useNavigate,useLocation } from 'react-router-dom'
import AccountApi from '../api/AccountApi'
import type { ColumnsType } from 'antd/es/table';
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/ArkreenTable.css'
import '../common/css/ArkreenMain.css'
import {formatLongString,formatVisualizationDate,formatAccountTypeIcon} from '../utils/DataFormatUtils'
import ArkreenTable from '../components/ArkreenTable'
import { useRecoilState } from "recoil";
import { AccountType } from "../store/types";
import { AccountsState } from "../store/atoms";



function Accounts(){

  const navigate  = useNavigate()
  const location  = useLocation()

  function showAccountDetail(address:string){
    navigate('/account/'+address)
  }



  const columns: ColumnsType<AccountType> = [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width:'180px',
      fixed: 'left',
    },
    {
      title: 'Reward(tAKRE)',
      dataIndex: 'earningMint',
      key: 'earningMint',
      width:'180px'
    },
    //{title: 'Miners',dataIndex: 'amountMiner',key: 'amountMiner',width:'100px',},
    {
      title: 'Type',
      dataIndex: 'accountType',
      key: 'accountType',
      width:'120px'
    },
    {
      title: 'Age',
      dataIndex: 'createTime',
      key: 'createTime',
      width:'180px'
    },
    ];


  const utm = new URLSearchParams(location.search)
  const [accounts,setAccounts] = useRecoilState(AccountsState)
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(utm.get("page") ? Number(utm.get("page")) : 1)

  async function handleAccounts() {
    //
    const data = {
      offset: currentPage
    }
    
    setLoading(true)
    const resp:any = await AccountApi.list(data)
    if(!resp.error){
      resp.result.forEach((item: any,index: number) => {
          const address = item.address
          resp.result[index].key = address
          resp.result[index].address = <span onClick={() => {showAccountDetail(address)}} className="content-td-link">{formatLongString(address,7,7)}</span>
          resp.result[index].earningMint = <span>{Number(Number(item.earningMint).toFixed(2)).toLocaleString() + ''}</span>
          resp.result[index].amountMiner = <span>{item.amountMiner}</span>
          resp.result[index].accountType = <span>{formatAccountTypeIcon(Number(item.accountType))}</span>
          resp.result[index].createTime = <span>{formatVisualizationDate(item.createTime)}</span>
      })
      setAccounts(resp.result)
    }else{
      if(accounts.length>0){
        setAccounts([])
      }
    }
    setLoading(false)
 }

  useEffect(() => {
    handleAccounts();
  },[currentPage])//eslint-disable-line

  return (
    <div className="arkreen-main">
      <div className="arkreen-main-title">
        <span>Accounts</span>
      </div>
      <div className="arkreen-main-content">
        <ArkreenTable scroll={{ x: 760 }}  currentPage={currentPage} columns={columns} datalist={accounts} loading={loading} onPageChange={setCurrentPage}/>
      </div>
    </div>
  );
}

export default Accounts;
