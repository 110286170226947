import React,{memo} from "react";
import { Skeleton } from 'antd';
import '../common/css/ArkreenGrowthTrendCard.css'
import ArkreenAreaChart from "./ArkreenAreaChart";

type cardProps = {
    icon?:React.ReactNode,
    title: string,
    value: string | number,
    href?: string,
    time: string | number,
    data: any,
    height?:string,
    xField?:string,
    yField?:string,
    unit?:string,
    lineColor?:string,
    type?:number
}
//
function ArkreenStatGrowthChart(prop: cardProps): JSX.Element {
    return (
        <div className="growth-trend-card-body">
            <div className="growth-trend-card-title">
                <div style={{flex:8}}>
                    {(prop.title)?<span>{prop.icon}{prop.title}</span>:<Skeleton.Input style={{width:'200px'}}  active size='small' />}
                </div>
            </div>
            <div className="growth-trend-card-value">
                {(prop.value)?<span>{prop.value}</span>:<Skeleton.Input style={{width:'180px'}}  active size='small' />}
            </div>
            <div style={{height: prop.height?prop.height:'', marginTop: '8px'}}>
                {(prop.data && prop.data.length>0)?<ArkreenAreaChart type={prop.type} unit={prop.unit} height={prop.height?prop.height:'192px'} data={prop.data} xField={prop.xField?prop.xField:'date'} yField={prop.yField?prop.yField:'value'} lineColor={prop.lineColor?prop.lineColor:"#00913A"} />:<Skeleton.Input style={{height:prop.height?prop.height:'192px'}} block={true} active size='small' />}
            </div>
        </div>
    )
}

export default memo(ArkreenStatGrowthChart);
    