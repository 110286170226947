import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';


export function ArkreenLogoTextUnionIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    //
    const LogoSvg = () => (
        <svg width="116" height="26" viewBox="0 0 116 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M108.015 1.2833L105.697 0.425074C105.495 0.345024 105.254 0.465098 105.173 0.665224C105.092 0.865349 105.213 1.1055 105.415 1.18555L106.762 1.68419L105.455 2.14582C105.254 2.22587 105.133 2.46602 105.214 2.66614C105.254 2.82624 105.415 2.94632 105.576 2.94632C105.617 2.94632 105.657 2.94632 105.738 2.90629L108.011 2.1027C108.099 2.09195 108.183 2.05537 108.242 1.98626C108.363 1.87597 108.42 1.69894 108.358 1.54544C108.301 1.40357 108.162 1.30193 108.015 1.2833Z" fill="#00913A"/>
            <path d="M99.61 4.50747C99.4488 4.50747 99.3278 4.42742 99.2472 4.26732C99.1666 4.06719 99.2472 3.82704 99.4488 3.74699L101.787 2.70634C101.839 2.68559 101.894 2.6756 101.948 2.67566C102.002 2.67559 102.057 2.68559 102.11 2.70634C102.161 2.72663 102.204 2.75721 102.239 2.79482C102.269 2.82627 102.294 2.86361 102.311 2.90646C102.32 2.92929 102.327 2.95264 102.332 2.97621C102.35 3.05734 102.344 3.14531 102.311 3.22666L101.223 5.46807C101.142 5.62817 100.981 5.70822 100.86 5.70822C100.82 5.70822 100.739 5.70822 100.699 5.66819C100.497 5.58814 100.417 5.34799 100.497 5.14787L101.119 3.86767L99.7713 4.46744C99.731 4.50747 99.6906 4.50747 99.61 4.50747Z" fill="#00913A"/>
            <path d="M40.0697 25.1996C40.9162 25.5999 41.7628 25.8 42.6899 25.8C43.0931 25.8 43.5365 25.76 44.0605 25.6799C44.5443 25.5999 44.8668 25.5198 45.028 25.4398V24.6393C44.5846 24.5593 44.1008 24.2791 43.5768 23.8789C43.0931 23.4786 42.5287 22.8783 41.9643 22.0778L37.5704 16.1543L40.755 12.192C41.2387 11.5916 41.7628 11.1914 42.2868 10.9112C42.8109 10.6311 43.4156 10.511 44.0605 10.511V9.63049H43.4155C42.2465 9.63049 41.3193 9.87064 40.6744 10.3109C40.0294 10.7512 39.3441 11.3915 38.6588 12.232L34.6679 17.235V7.62932C34.6679 6.50866 34.547 5.62814 34.3857 4.98776C34.2245 4.34739 33.9423 3.90713 33.4989 3.62697C33.0555 3.3468 32.4508 3.22673 31.6849 3.22673H27.7343V4.22732H28.0568C29.0646 4.22732 29.6693 4.54751 29.9515 5.14786C30.2336 5.74821 30.3546 6.58871 30.3546 7.58929V25.4398H34.6276V18.8359L37.7719 23.0784C38.4975 24.119 39.2635 24.7994 40.0697 25.1996Z" fill="#00913A"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.68394 25.7998C4.75677 25.7998 3.95054 25.6797 3.22493 25.4796C2.53963 25.2395 1.93496 24.9593 1.45122 24.5591C0.967481 24.1588 0.604675 23.6786 0.362805 23.1182C0.120935 22.5579 0 21.9175 0 21.2371C0 20.1565 0.24187 19.236 0.765921 18.4755C1.24966 17.7151 2.17683 17.1547 3.4668 16.7545C4.75677 16.3543 6.61111 16.1541 8.98949 16.1541H9.47323C9.47323 14.7933 9.3523 13.7127 9.11043 12.9122C8.86856 12.1118 8.50575 11.5514 8.02201 11.2312C7.53827 10.9111 6.97391 10.7109 6.32893 10.7109C5.56301 10.7109 4.91802 10.9111 4.31335 11.2713C3.70867 11.6715 3.26524 12.472 3.02337 13.7527H1.20935C1.20935 13.1524 1.24966 12.552 1.33028 11.9517C1.41091 11.3513 1.65278 10.791 2.0559 10.3507C2.45901 9.91047 3.104 9.59028 4.03116 9.43019C4.95833 9.27009 5.96612 9.19005 7.05453 9.19005C8.14295 9.19005 9.11043 9.35014 9.95697 9.63031C10.8035 9.91047 11.5291 10.3507 12.0935 10.9511C12.6982 11.5514 13.1416 12.2719 13.4238 13.1924C13.706 14.0729 13.8672 15.1536 13.8672 16.3543V25.5196H11.0454L9.87635 23.9988H9.7151C9.43292 24.479 8.94918 24.9193 8.26388 25.2795C7.49796 25.5997 6.69173 25.7998 5.68394 25.7998ZM6.69173 24.0788C7.41734 24.0788 8.02202 23.8387 8.50576 23.3184C8.98949 22.8381 9.31199 22.3178 9.51355 21.8375V17.635H8.98949C7.37703 17.635 6.2483 17.9152 5.563 18.5155C4.87771 19.1159 4.5149 19.9964 4.5149 21.1971C4.5149 22.1577 4.71646 22.8781 5.07927 23.3584C5.48238 23.8387 6.00643 24.0788 6.69173 24.0788Z" fill="#00913A"/>
            <path d="M18.0193 25.4395V13.9928C18.0193 12.9922 17.8984 12.1517 17.6162 11.5513C17.334 10.951 16.7293 10.6308 15.7215 10.6308H15.4394V9.6302H18.7046C19.4705 9.6302 20.0349 9.71025 20.4783 9.91037C20.9217 10.1105 21.2442 10.4307 21.4861 10.8709C21.8892 10.5107 22.4133 10.1505 23.0986 9.79029C23.7839 9.43008 24.6304 9.26999 25.6382 9.26999C26.1219 9.26999 26.5654 9.31001 26.9685 9.43008C27.3716 9.55015 27.6941 9.71025 27.9763 9.95039C28.3391 10.2306 28.6213 10.5507 28.8228 10.911C28.9941 11.251 29.0781 11.4178 29.0994 11.4356L27.4119 12.752C27.2104 12.4719 26.9282 12.1517 26.5251 11.8715C26.1219 11.5914 25.6785 11.4313 25.1142 11.4313C24.4289 11.4313 23.8242 11.5513 23.3404 11.7514C22.8567 11.9516 22.4536 12.1917 22.1714 12.5119C22.1798 12.5954 22.1882 12.6755 22.1963 12.752C22.2268 13.0423 22.252 13.2824 22.252 13.4725C22.2923 13.7126 22.2923 13.9527 22.2923 14.1128V25.4395H18.0193Z" fill="#00913A"/>
            <path d="M29.105 11.4313L29.0994 11.4356C29.1032 11.4388 29.105 11.4373 29.105 11.4313Z" fill="#00913A"/>
            <path d="M47.9708 25.4395V13.9928C47.9708 12.9922 47.8499 12.1517 47.5677 11.5513C47.2855 10.951 46.6809 10.6308 45.6731 10.6308H45.3506V9.6302H48.6158C49.3817 9.6302 49.9461 9.71025 50.3895 9.91037C50.833 10.1105 51.1554 10.4307 51.3973 10.8709C51.8004 10.5107 52.3245 10.1505 53.0098 9.79029C53.6951 9.43008 54.5416 9.26999 55.5494 9.26999C56.0332 9.26999 56.4766 9.31001 56.8797 9.43008C57.2828 9.55015 57.6053 9.71025 57.8875 9.95039C58.2503 10.2306 58.5325 10.5507 58.734 10.911C58.9053 11.2511 58.9893 11.4178 59.0106 11.4356L57.3231 12.752C57.1216 12.4719 56.8394 12.1517 56.4363 11.8715C56.0332 11.5914 55.5897 11.4313 55.0254 11.4313C54.3401 11.4313 53.7354 11.5513 53.2517 11.7514C52.7679 11.9516 52.3648 12.1917 52.0826 12.5119C52.091 12.5955 52.0994 12.6756 52.1075 12.7521C52.138 13.0424 52.1632 13.2825 52.1632 13.4725C52.2035 13.7126 52.2035 13.9527 52.2035 14.1128V25.4395H47.9708Z" fill="#00913A"/>
            <path d="M59.0162 11.4313L59.0106 11.4356C59.0144 11.4388 59.0162 11.4373 59.0162 11.4313Z" fill="#00913A"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M62.5635 24.7191C63.7325 25.4395 65.0628 25.7997 66.5947 25.7997C67.804 25.7997 68.8118 25.5996 69.6987 25.1593C70.5049 24.7591 71.1499 24.3188 71.6739 23.7585C72.198 23.1982 72.6011 22.6779 72.9236 22.1976L71.9158 21.4371C71.3514 22.2376 70.7065 22.918 69.9808 23.4383C69.2552 23.9586 68.3281 24.2388 67.1993 24.2388C66.1109 24.2388 65.2644 23.7585 64.5791 22.838C63.8938 21.9174 63.5713 20.3565 63.5713 18.1152H73.488L73.6089 15.9939C73.6089 14.6732 73.2864 13.4725 72.6414 12.4719C71.9964 11.4713 71.1096 10.6708 70.0212 10.1105C68.8924 9.55016 67.6831 9.26999 66.3125 9.26999C64.9419 9.26999 63.6922 9.59018 62.6038 10.2306C61.4751 10.8309 60.6285 11.7514 59.9836 12.9922C59.3386 14.1929 59.0161 15.7138 59.0161 17.5148C59.0161 19.1158 59.3386 20.5166 59.9432 21.7573C60.5479 22.9981 61.4348 23.9986 62.5635 24.7191ZM69.0537 16.6343H63.4504C63.4504 15.1534 63.6116 13.9928 63.8535 13.1122C64.1357 12.2718 64.4582 11.6714 64.9016 11.3112C65.345 10.951 65.7884 10.7909 66.2722 10.7909C67.0784 10.7909 67.7637 11.1911 68.2878 12.0316C68.8118 12.8721 69.0537 14.393 69.0537 16.6343Z" fill="#00913A"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M82.9613 25.7997C81.4294 25.7997 80.0991 25.4395 78.9301 24.7191C77.8014 23.9986 76.9145 22.9981 76.3098 21.7573C75.7052 20.5166 75.3827 19.1158 75.3827 17.5148C75.3827 15.7138 75.7052 14.1929 76.3502 12.9922C76.9951 11.7514 77.8417 10.8309 78.9704 10.2306C80.0588 9.59018 81.3085 9.26999 82.6791 9.26999C84.0497 9.26999 85.259 9.55016 86.3877 10.1105C87.4762 10.6708 88.363 11.4713 89.008 12.4719C89.653 13.4725 89.9755 14.6732 89.9755 15.9939L89.8546 18.1152H79.9379C79.9379 20.3565 80.2604 21.9174 80.9457 22.838C81.631 23.7585 82.4775 24.2388 83.5659 24.2388C84.6947 24.2388 85.6218 23.9586 86.3474 23.4383C87.073 22.918 87.718 22.2376 88.2824 21.4371L89.2902 22.1976C88.9677 22.6779 88.5646 23.1982 88.0405 23.7585C87.5165 24.3188 86.8715 24.7591 86.0653 25.1593C85.1784 25.5996 84.1706 25.7997 82.9613 25.7997ZM79.817 16.6343H85.4203C85.4203 14.393 85.1784 12.8721 84.6544 12.0316C84.1303 11.1911 83.445 10.7909 82.6388 10.7909C82.155 10.7909 81.7116 10.951 81.2682 11.3112C80.8247 11.6714 80.5023 12.2718 80.2201 13.1122C79.9782 13.9928 79.817 15.1534 79.817 16.6343Z" fill="#00913A"/>
            <path d="M93.1599 13.9928V25.4395H97.5136V11.7915C97.8361 11.5513 98.1989 11.3512 98.6826 11.1911C99.126 11.031 99.6904 10.951 100.295 10.951C100.9 10.951 101.464 11.1111 101.908 11.3912C102.351 11.6714 102.714 12.1517 102.996 12.7921C103.278 13.4725 103.399 14.313 103.399 15.4336V25.4395H107.672V15.4736C107.672 13.9928 107.43 12.8321 106.946 11.9115C106.463 10.991 105.777 10.3506 104.85 9.91037C103.923 9.47011 102.754 9.26999 101.383 9.26999C100.053 9.26999 99.0051 9.47011 98.2795 9.79029C97.5539 10.1105 96.9895 10.4707 96.5864 10.8709C96.3445 10.4307 96.022 10.1105 95.5786 9.91037C95.1352 9.71025 94.5708 9.6302 93.8049 9.6302H90.5397V10.6308H90.8622C91.8699 10.6308 92.4746 10.951 92.7568 11.5513C93.039 12.1517 93.1599 12.9922 93.1599 13.9928Z" fill="#00913A"/>
            <path d="M112.388 4.16403L111.099 3.54686C110.897 3.46681 110.656 3.54686 110.575 3.74699C110.494 3.94711 110.575 4.18726 110.776 4.26731L112.966 5.31528C112.994 5.33669 113.025 5.35337 113.058 5.36533C113.101 5.38802 113.162 5.38801 113.195 5.38799L113.198 5.38799C113.24 5.38796 113.317 5.38694 113.356 5.34797C113.448 5.31157 113.515 5.24209 113.553 5.15832C113.554 5.15486 113.556 5.15139 113.558 5.14786C113.574 5.1081 113.584 5.06675 113.587 5.02539C113.59 4.99823 113.589 4.97065 113.587 4.94311C113.583 4.9041 113.573 4.86516 113.558 4.82764L112.51 2.50619C112.429 2.30607 112.187 2.22602 111.986 2.30607C111.784 2.38612 111.704 2.62627 111.784 2.82639L112.388 4.16403Z" fill="#00913A"/>
            <path d="M114.098 10.8915C114.083 11.063 114.192 11.2396 114.357 11.3086C114.371 11.3159 114.385 11.3223 114.4 11.3278C114.458 11.3513 114.491 11.3513 114.525 11.3513C114.578 11.3513 114.614 11.3513 114.644 11.3399C114.73 11.3211 114.795 11.2784 114.839 11.2062C114.916 11.1226 114.96 11.0097 114.953 10.8982L115.775 8.70968C115.855 8.50955 115.734 8.2694 115.533 8.18935C115.331 8.1093 115.089 8.22937 115.009 8.4295L114.535 9.69235L114.082 8.4295C114.001 8.22937 113.759 8.1093 113.558 8.18935C113.356 8.2694 113.235 8.50955 113.316 8.70968L114.098 10.8915Z" fill="#00913A"/>
            <path d="M110.902 16.3242C110.901 16.321 110.899 16.3177 110.897 16.3144C110.882 16.2769 110.873 16.238 110.869 16.1989C110.865 16.1661 110.866 16.1332 110.87 16.1011C110.874 16.065 110.883 16.0291 110.897 15.9944L111.986 13.753C112.066 13.5529 112.308 13.4728 112.51 13.5529C112.711 13.6329 112.792 13.8731 112.711 14.0732L112.095 15.3431L113.397 14.7534C113.598 14.6734 113.84 14.7534 113.921 14.9536C114.001 15.1537 113.921 15.3938 113.719 15.4739L111.508 16.4579C111.43 16.5225 111.337 16.5548 111.26 16.5548C111.226 16.5548 111.165 16.5548 111.122 16.5315C111.073 16.5138 111.03 16.486 110.992 16.448C110.953 16.4133 110.923 16.371 110.902 16.3242Z" fill="#00913A"/>
        </svg>

    )
    //
    return (
        <Icon component={LogoSvg} {...props} />
    );
}