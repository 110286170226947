import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const svg = () => (
    <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V14.68C10.25 15.0942 10.5858 15.43 11 15.43C11.4142 15.43 11.75 15.0942 11.75 14.68V8Z" fill="#101020"/>
        <path d="M6.75 9.73999C6.75 9.32578 6.41421 8.98999 6 8.98999C5.58579 8.98999 5.25 9.32578 5.25 9.73999V12.94C5.25 13.3542 5.58579 13.69 6 13.69C6.41421 13.69 6.75 13.3542 6.75 12.94V9.73999Z" fill="#101020"/>
        <path d="M16 8.98999C16.4142 8.98999 16.75 9.32578 16.75 9.73999V12.94C16.75 13.3542 16.4142 13.69 16 13.69C15.5858 13.69 15.25 13.3542 15.25 12.94V9.73999C15.25 9.32578 15.5858 8.98999 16 8.98999Z" fill="#101020"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8 0.25C5.42603 0.25 3.42914 0.760201 2.09467 2.09467C0.760201 3.42914 0.25 5.42603 0.25 8V14C0.25 16.574 0.760201 18.5709 2.09467 19.9053C3.42914 21.2398 5.42603 21.75 8 21.75H14C16.574 21.75 18.5709 21.2398 19.9053 19.9053C21.2398 18.5709 21.75 16.574 21.75 14V8C21.75 5.42603 21.2398 3.42914 19.9053 2.09467C18.5709 0.760201 16.574 0.25 14 0.25H8ZM1.75 8C1.75 5.57397 2.2398 4.07086 3.15533 3.15533C4.07086 2.2398 5.57397 1.75 8 1.75H14C16.426 1.75 17.9291 2.2398 18.8447 3.15533C19.7602 4.07086 20.25 5.57397 20.25 8V14C20.25 16.426 19.7602 17.9291 18.8447 18.8447C17.9291 19.7602 16.426 20.25 14 20.25H8C5.57397 20.25 4.07086 19.7602 3.15533 18.8447C2.2398 17.9291 1.75 16.426 1.75 14V8Z" fill="#101020"/>
    </svg>

);

let prop:any = undefined

export function ArkreenConsoleIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={svg} {...props} />
    );
}