import axios from 'axios'

console.log("use api:"+process.env.REACT_APP_BASE_API)

const config = {
    //baseURL: "https://101.35.163.23:9802",
    //baseURL: 'https://testapi.arkreen.com',
    baseURL: process.env.REACT_APP_BASE_API,
    timeout: 30000,
    headers: {'Content-Type': 'application/json',}
};

const request = axios.create(config);

request.interceptors.response.use(
    (response: any) => {
        if (response.data.code) {
            console.log(JSON.stringify(response))
        }
        return response.data
    },
    (error: any) => {
        let { message } = error;
        if (message === "Network Error") {
            //message = "后端接口连接异常";
            message = "Network Error,can't connect to Service";
        }
        else if (message.includes("timeout")) {
            //message = "系统接口请求超时";
            message = "System interface request timeout";
        }
        else if (message.includes("Request failed with status code")) {
            //message = "系统接口" + message.substr(message.length - 3) + "异常";
            message = "System interface " + message.substr(message.length - 3) + " error";
        }
        console.error(message)
        return Promise.reject(error)
    })

/**
 * 发送http请求
 * @param url
 * @param method
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
async function sendJsonRPC(url: string,method: string,params: object) {
    let data = {
        jsonrpc: "2.0",
        id: Number(Math.random().toString().substring(2)),
        method: method,
        params: params
    }
    //console.info(method + " request data:", JSON.stringify(data))
    return await request({url: url, method: 'post', data: data})
}

/**
 * 发送post请求
 * @param url 
 * @param params 
 * @returns 
 */
async function post(url: string,params:any){
    return await request({url: url, method: 'post', data: params})
}

/**
 * 
 * @param url 发送get请求
 * @returns 
 */
async function get(url: string){
    return await request({url: url, method: 'get'})
}

const HttpUtils ={
    sendJsonRPC:sendJsonRPC,
    post:post,
    get:get,
}

export default HttpUtils;
