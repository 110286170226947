import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const fillStyle = {fill: 'currentColor'}

const Svg = () => (

  <svg width={prop.width?prop.width:"1.1em"} height={prop.height?prop.height:"1.1em"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_889_6980)">
    <path d="M2 2.66667C2 2.29848 2.29848 2 2.66667 2H6.66667C7.03486 2 7.33333 2.29848 7.33333 2.66667V6.66667C7.33333 7.03486 7.03486 7.33333 6.66667 7.33333H2.66667C2.29848 7.33333 2 7.03486 2 6.66667V2.66667Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#40404D"}/>
    <path d="M8.66667 2.66667C8.66667 2.29848 8.96514 2 9.33333 2H13.3333C13.7015 2 14 2.29848 14 2.66667V6.66667C14 7.03486 13.7015 7.33333 13.3333 7.33333H9.33333C8.96514 7.33333 8.66667 7.03486 8.66667 6.66667V2.66667Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#40404D"}/>
    <path d="M2 9.33333C2 8.96514 2.29848 8.66667 2.66667 8.66667H6.66667C7.03486 8.66667 7.33333 8.96514 7.33333 9.33333V13.3333C7.33333 13.7015 7.03486 14 6.66667 14H2.66667C2.29848 14 2 13.7015 2 13.3333V9.33333Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#40404D"}/>
    <path d="M8.66667 10.6667C8.66667 10.2985 8.96514 10 9.33333 10H10C10.3682 10 10.6667 10.2985 10.6667 10.6667V13.3333C10.6667 13.7015 10.3682 14 10 14H9.33333C8.96514 14 8.66667 13.7015 8.66667 13.3333V10.6667Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#40404D"}/>
    <path d="M12 9.33333C12 8.96514 12.2985 8.66667 12.6667 8.66667H13.3333C13.7015 8.66667 14 8.96514 14 9.33333V13.3333C14 13.7015 13.7015 14 13.3333 14H12.6667C12.2985 14 12 13.7015 12 13.3333V9.33333Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#40404D"}/>
    </g>
    <defs>
    <clipPath id="clip0_889_6980">
    <rect width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} fill="white"/>
    </clipPath>
    </defs>
  </svg>


);

let prop:any = undefined

export function QrCodeIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={Svg} {...props} />
    );
}

