import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const svg = () => (
    <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 0.75C17.4142 0.75 17.75 1.08579 17.75 1.5C17.75 1.91421 17.4142 2.25 17 2.25H1C0.585787 2.25 0.25 1.91421 0.25 1.5C0.25 1.08579 0.585787 0.75 1 0.75H17Z" fill={prop.color?prop.color:"#222432"}/>
        <path d="M17.6999 7.72986C17.7323 7.81368 17.75 7.90477 17.75 8C17.75 8.10929 17.7266 8.21312 17.6846 8.30676C17.6567 8.36894 17.62 8.4281 17.5744 8.48232C17.559 8.50065 17.5427 8.51822 17.5256 8.53499L14.7019 11.3587C14.409 11.6516 13.9342 11.6516 13.6413 11.3587C13.3484 11.0659 13.3484 10.591 13.6413 10.2981L15.1893 8.75H1C0.585787 8.75 0.25 8.41421 0.25 8C0.25 7.58579 0.585787 7.25 1 7.25H15.1894L13.6413 5.70183C13.3484 5.40894 13.3484 4.93406 13.6413 4.64117C13.9342 4.34828 14.409 4.34829 14.7019 4.64118L17.5303 7.46963C17.6068 7.54613 17.6633 7.63503 17.6999 7.72986Z" fill={prop.color?prop.color:"#222432"}/>
        <path d="M17.75 14.5C17.75 14.0858 17.4142 13.75 17 13.75H1C0.585787 13.75 0.25 14.0858 0.25 14.5C0.25 14.9142 0.585787 15.25 1 15.25H17C17.4142 15.25 17.75 14.9142 17.75 14.5Z" fill={prop.color?prop.color:"#222432"}/>
    </svg>
);

let prop:any = undefined

export function ArkreenMenuSwitchRightIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={svg} {...props} />
    );
}