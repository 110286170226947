import HttpUtils from '../utils/HttpUtils'

async function statMinerGreenEnergyTrend(data?: any) {
    const params = {
        address: data.address,
        endTime: data.endTime,
        startTime: data.startTime
    }
    return await HttpUtils.sendJsonRPC('/v1','net_statMinerGreenEnergyTrend',params);
}

async function statMinerDailyReward(data?: any) {
    const params = {
        address: data.address,
        endTime: data.endTime,
        startTime: data.startTime
    }
    return await HttpUtils.sendJsonRPC('/v1','net_statMinerDailyReward',params);
}

async function statOwnerDailyReward(data?: any) {
    const params = {
        ownerAddress: data.address,
        endDay: data.endTime,
        startDay: data.startTime
    }
    return await HttpUtils.sendJsonRPC('/v1','net_statDeviceDailyByOwner',params);
}

async function search(criteria: string) {
    const params = {
        "criteria": criteria
    }
    return await HttpUtils.sendJsonRPC('/v1','net_search',params);
}

async function stateNetworkTrend() {
    // net_stateNetworkTrend
    return await HttpUtils.sendJsonRPC('/v1','net_statNetwork',{});
}

async function statDeviceDaily(ownerAddress?:string) {
    return await HttpUtils.sendJsonRPC('/v1','net_statDeviceDailyAll',ownerAddress?{ownerAddress:ownerAddress}:{});
}

async function statDeviceDailyByMiner(data?: any) {
    const params = {
        minerAddress: data.address,
        endDay: data.endTime,
        startDay: data.startTime
    }
    return await HttpUtils.sendJsonRPC('/v1','net_statDeviceDailyByMiner',params);
}


const StatApi ={
    statMinerGreenEnergyTrend:statMinerGreenEnergyTrend,
    statMinerDailyReward:statMinerDailyReward,
    stateNetworkTrend:stateNetworkTrend,
    statOwnerDailyReward:statOwnerDailyReward,
    statDeviceDaily:statDeviceDaily,
    search:search,
    statDeviceDailyByMiner:statDeviceDailyByMiner
}

export default StatApi;