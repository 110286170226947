import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const fillStyle = {fill: 'currentColor'}

const Svg = () => (

  <svg width={prop.width?prop.width:"1em"} height={prop.height?prop.height:"1em"} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.3149 1.23499C10.8561 1.02983 10.3021 0.945007 9.78953 0.945007C9.27694 0.945007 8.72294 1.02983 8.26415 1.23499L8.26303 1.23549L2.3668 3.85382C1.4418 4.2621 0.644531 4.94813 0.644531 5.91001C0.644531 6.87185 1.44095 7.55751 2.36591 7.9658L8.26303 10.5845L8.26431 10.5851C8.72307 10.7902 9.27699 10.875 9.78953 10.875C10.3021 10.875 10.856 10.7902 11.3148 10.5851L11.316 10.5845L17.2123 7.9662C18.1373 7.55791 18.9345 6.87189 18.9345 5.91001C18.9345 4.9481 18.138 4.2624 17.213 3.85413L11.316 1.23549L11.3149 1.23499ZM9.78953 2.44501C9.41754 2.44501 9.08702 2.51005 8.87603 2.60452L8.87392 2.60547L2.97226 5.2262C2.19761 5.56796 2.14453 5.87716 2.14453 5.91001C2.14453 5.94285 2.19761 6.25206 2.97226 6.59382L2.97392 6.59455L8.87603 9.21549C9.08702 9.30997 9.41754 9.37501 9.78953 9.37501C10.1615 9.37501 10.492 9.30997 10.703 9.21549L10.7051 9.21455L16.6051 6.59455L16.6068 6.59382C17.3815 6.25206 17.4345 5.94285 17.4345 5.91001C17.4345 5.87716 17.3815 5.56796 16.6068 5.2262L10.7051 2.60546L10.703 2.60452C10.492 2.51005 10.1615 2.44501 9.78953 2.44501Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
    <path d="M1 9.25C1.41421 9.25 1.75 9.58579 1.75 10C1.75 10.2434 1.84628 10.5559 2.0399 10.8535C2.23351 11.151 2.48007 11.3655 2.70295 11.4639L2.70479 11.4647L9.49338 14.4841C9.82187 14.6291 10.1898 14.6269 10.5017 14.4863L10.5052 14.4847L17.2952 11.4647L17.2971 11.4639C17.5199 11.3655 17.7665 11.151 17.9601 10.8535C18.1537 10.5559 18.25 10.2434 18.25 10C18.25 9.58579 18.5858 9.25 19 9.25C19.4142 9.25 19.75 9.58579 19.75 10C19.75 10.5966 19.5313 11.1891 19.2174 11.6715C18.9037 12.1537 18.4505 12.5941 17.9038 12.8357L11.1183 15.8537L11.1163 15.8546C10.4089 16.1729 9.59727 16.1702 8.88662 15.8559L2.09705 12.8361L2.09603 12.8356C1.54937 12.594 1.0963 12.1537 0.782602 11.6715C0.468717 11.1891 0.25 10.5966 0.25 10C0.25 9.58579 0.585786 9.25 1 9.25Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
    <path d="M1.75 15C1.75 14.5858 1.41421 14.25 1 14.25C0.585786 14.25 0.25 14.5858 0.25 15C0.25 16.229 0.976812 17.3353 2.0939 17.8347L8.88662 20.8559C9.59727 21.1702 10.4089 21.1729 11.1163 20.8546L11.1183 20.8537L17.9048 17.8353L17.9061 17.8347C19.0232 17.3353 19.75 16.229 19.75 15C19.75 14.5858 19.4142 14.25 19 14.25C18.5858 14.25 18.25 14.5858 18.25 15C18.25 15.6308 17.8765 16.2046 17.2939 16.4653L10.5052 19.4847L10.5017 19.4863C10.1898 19.6269 9.82187 19.6291 9.49338 19.4841L2.70554 16.4651C2.12295 16.2043 1.75 15.6308 1.75 15Z" style={fillStyle} fill={prop.style?.color?prop.style.color:"#101020"}/>
  </svg>




);

let prop:any = undefined

export function TransactionLineIcon(props: Partial<CustomIconComponentProps>): JSX.Element {
    prop = props
    return (
        <Icon component={Svg} {...props} />
    );
}

