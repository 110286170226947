
import { Select } from 'antd';
import { useState,useEffect  } from "react";
import { useNavigate,useLocation } from 'react-router-dom'
import MinerApi from '../api/MinerApi'
import type { ColumnsType } from 'antd/es/table';
import '../common/css/LinkButton.css'
import '../common/css/ArkreenMinViewTable.css'
import '../common/css/ArkreenSelect.css'
import '../common/css/ArkreenMain.css'
import '../common/css/ArkreenTable.css'
import {formatLongString,formatVisualizationDate,formatStatusIcon,formatMinerTypeTag, formatElectricity2} from '../utils/DataFormatUtils'
import ArkreenTable from '../components/ArkreenTable'
import {MinerType} from '../store/types'

interface DataType {
  address: string;
  minerType: number;
  runStatus: number;
  status:number;
  ratedPower: string;
  totalEnergyGeneration: number;
  withdrawAmount: string;
  onboardTime: number;
  maker: string;
  earningMint:string;
}

function Miners(){
  const location  = useLocation()
  const navigate  = useNavigate()

  function showMinerDetail(address:string){
    navigate('/miner/'+address)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width:'160px',
      fixed: 'left',
    },
    {
      title: 'Type',
      dataIndex: 'minerType',
      key: 'minerType',
      width:'160px'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width:'150px'
    },
    {
      title: 'Maker',
      dataIndex: 'maker',
      key: 'maker',
      width:'180px'
    },
    {
      title: 'Reward(tAKRE)',
      dataIndex: 'totalReward',
      key: 'totalReward',
      width:'180px'
    },
    {
      title: 'Capacity(W)',
      dataIndex: 'capacity',
      key: 'capacity',
      width:'160px'
    },
    {
      title: 'Energy(kWh)',
      dataIndex: 'totalEnergyGeneration',
      key: 'totalEnergyGeneration',
      width:'160px'
    },
    {
      title: 'Onboarding Time',
      dataIndex: 'onboardTime',
      key: 'onboardTime',
      width:'180px'
    },
  ];
  
  const [minerList,setMinerList] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const utm = new URLSearchParams(location.search)
  const [currentPage, setCurrentPage] = useState(utm.get("page") ? Number(utm.get("page")) : 1)
  const [minerType, setMinerType] = useState(utm.get("type") ? Number(utm.get("type")) : undefined)
  const [lifecycle, setLifecycle] = useState(utm.get("lifecycle") ? Number(utm.get("lifecycle")) : undefined)
  
  async function getMiners() {
    const data = {
      offset:currentPage,
      type:minerType,
      status:lifecycle,
    }
    setLoading(true)
    const rsp:any = await MinerApi.list(data);
    if(!rsp.error){
      const minerList:any = []
      rsp.result.forEach((item: MinerType,index: number) => {
        const address = item.address;
        let status = undefined;
        if(item.status===2){
          if(item.runStatus === 0){
            status =  formatStatusIcon('success','Normal')
          }else{
            status = formatStatusIcon('warning','Abnormal')
          }
        }else if(item.status===3){
          status = formatStatusIcon('danger','Terminated')
        }else {
          status = formatStatusIcon('warning','Abnormal')
        }
        const miner = {
          key: item.address,
          address: <span onClick={() => {showMinerDetail(address)}} className="content-td-link">{formatLongString(address,7,7)}</span>,
          minerType: <span>{formatMinerTypeTag(Number(item.minerType))}</span>,
          maker: item.maker,
          totalReward: <span>{Number(Number(item.totalReward).toFixed(2)).toLocaleString()}</span>,
          capacity: <span>{Number(item.capacity/1000).toLocaleString()}</span>,
          totalEnergyGeneration: <span>{formatElectricity2(Number(item.totalEnergyGeneration)/1000)}</span>,
          onboardTime: <span>{formatVisualizationDate(item.onboardTime)}</span>,
          status: status,
        }
        minerList.push(miner)
      })
      setMinerList(minerList)
    }else{
      if(minerList.length>0){
        setMinerList([])
      }
    }
    setLoading(false)
}

 function handleMinerTypeChange(value: string) {
  let data = new URLSearchParams(location.search);
  if(!value){
    data.delete('type')
  }else{
    data.set('type',value)
  }
  data.set('page',"1")
  navigate("?"+data.toString());
  setMinerType(Number(value));
  setCurrentPage(1)
};

function handleLifecycleChange(value: string) {
  let data = new URLSearchParams(location.search);
  if(!value){
    data.delete('lifecycle')
  }else{
    data.set('lifecycle',value)
  }
  data.set('page',"1")
  navigate("?"+data.toString());
  setLifecycle(Number(value));
  setCurrentPage(1)
};

 useEffect(() => {getMiners();},[currentPage,minerType,lifecycle])//eslint-disable-line

  return (
    <div className="arkreen-main">
      <div className="arkreen-main-title">
        <span>Miners</span>
      </div>
      <div className="arkreen-main-content">
        <div className='arkreen-select-group'>
          <div className='arkreen-select-item arkreen-select-padding-right'>
            <span className='arkreen-select-title'> Miner Type </span>
            <Select
              allowClear = {true}
              className ='arkreen-select'
              defaultValue={minerType?""+minerType:null}
              onChange={handleMinerTypeChange}
              placeholder='Miner Type'
              options={[
                { value: '2', label: 'Remote Miner' },
                { value: '5', label: 'Standard Miner' }
            ]}/>
          </div>
          <div className='arkreen-select-item arkreen-select-padding-left'>
            <span className='arkreen-select-title'> Lifecycle </span>
              <Select
                allowClear = {true}
                defaultValue={lifecycle?""+lifecycle:null}
                placeholder='Lifecycle'
                className ='arkreen-select'
                onChange={handleLifecycleChange}
                options={[
                  { value: '0', label: 'Active' },
                  { value: '1', label: 'Terminated' }
              ]}/>
          </div>
        </div>
        <ArkreenTable scroll={{ x: 1350 }} currentPage={currentPage} columns={columns} datalist={minerList} loading={loading} onPageChange={setCurrentPage}/>
      </div>
    </div>
  );
}

export default Miners;